type CallbackFunction = (data?: any) => void;

class Event {
    events: Record<string, CallbackFunction[]>;

    constructor() {
        this.events = {};
    }

    $on(event: string, callback: CallbackFunction) {
        this.events[event] = this.events[event] || [];
        this.events[event].push(callback);
    }

    $off(event: string, callback: CallbackFunction) {
        if (!this.events[event]) {
            return;
        }

        this.events[event] = this.events[event].filter(handler => handler !== callback);
    }

    $once(event: string, callback: CallbackFunction) {
        const handler = (...args: any) => {
            this.$off(event, handler);
            
            callback(args);
        }

        this.$on(event, handler);
    }

    $emit(event: string, data?: any) {
        if (this.events[event]) {
            this.events[event].forEach(function(callback: CallbackFunction) {
                callback(data);
            });
        }
    }
}

export const EventBus = new Event();
