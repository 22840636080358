
import { Component, Mixins, Watch } from 'vue-property-decorator';
import UserModule from '@/admin/store/user';
import RoleModule, { RoleEnum } from '@/admin/store/role';

import LotMixin from '../lotCardMixin';
import AuctionLotEntity from '@/admin/store/auctions/lots/entity';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import RemoveModal from '@/components/modules/removeModal.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { tabsNavInterface } from '@/interfaces/tabs';
import Delimeter from '@/components/Delimeter.vue';

import LocalizationLayout from '@/admin/layouts/LocalizationLayout.vue';

@Component({
    components: {
        RemoveModal,
        TabsNav,
        Delimeter,
    },
})
export default class AuctionLotCardModuleInfo extends Mixins(LotMixin, LocalizationLayout) {
    componentCounter = 0;

    get isAllowRemove(): boolean {
        return AuctionLotEntity.isAllowRemove;
    }

    get tabsNav(): tabsNavInterface {
        return AuctionLotEntity.tabsNav;
    }

    get currentTab(): string {
        return AuctionLotEntity.tabsNav.card.id;
    }

    get componentCounterTriggers(): boolean[] {
        return [
            this.model.isPostauctionSale.list[0].checked,
            this.model.hideImage.list[0].checked,
            this.model.hidePrice.list[0].checked,
            this.model.isPriceReserve.list[0].checked,
        ];
    }

    get isPostauctionSaleChecked(): boolean {
        return this.model.isPostauctionSale.list[0].checked;
    }

    get role(): RoleEnum | null {
        return UserModule.role;
    }

    get isDisabledInputs(): boolean {
        const status = this.model.status;

        return (
            status === 'published' ||
            status === 'correspondence_bidding' ||
            status === 'online_auction' ||
            status === 'sold' ||
            status === 'not_sold' ||
            status === 'post_auction' ||
            status === 'withdrawn_from_sale' ||
            status === 'deleted'
        );
    }

    get isAllowEdit(): boolean {
        if (!this.role?.length) {
            return false;
        }

        let bool = true;
        const isRoleSuperAdmin = this.role === 'ROLE_SUPER_ADMIN';
        const isRoleWriteAllowed = RoleModule.accessByRouterName[this.$route.name as RoleEnum]?.write.includes(
            this.role as RoleEnum,
        );

        if (!isRoleWriteAllowed) {
            bool = false;
        }

        if (this.model.status === 'pre_auction' && this.model.bidsCount && !isRoleSuperAdmin) {
            bool = false;
        }

        if ((this.model.status === 'sold' || this.model.status === 'not_sold') && !isRoleSuperAdmin) {
            bool = false;
        }

        return bool;
    }

    get isPriceReserveDisabled(): boolean {
        return (
            (this.model.buyNowPriceType.value === 'reserve' && this.model.isPostauctionSale.list[0].checked) ||
            this.model.isPriceReserve.disabled
        );
    }

    get isPriceBuyNowRequired(): boolean {
        return (
            this.model.isPostauctionSale.list[0].checked &&
            !['start', 'reserve'].includes(this.model.buyNowPriceType.value)
        );
    }

    @Watch('componentCounterTriggers', { immediate: true })
    onComponentCounterTriggersChange(): void {
        this.componentCounter++;
    }

    @Watch('isPostauctionSaleChecked')
    async onIsPostauctionSaleCheckedChange(newVal: boolean): Promise<void> {
        if (newVal === true) {
            await this.$nextTick();
            this.initMask();
        }
    }

    updateBuyNowPriceType(params: string): void {
        AuctionLotEntity.updateBuyNowPriceType(params);
    }

    updateSearchAuction(search: string): void {
        AuctionLotEntity.searchCatalogAuction(search);
    }

    updateSelectedAuction(selected: InputSuggestionItem): void {
        AuctionLotEntity.updateSuggestionsSelectedByType({ selected, type: 'auction' });

        if (selected.id) {
            AuctionLotEntity.updateLotDataByCatalogId(+selected.id);
        }
    }

    updateSearchAuctionHouse(search: string): void {
        AuctionLotEntity.searchAuctionHouse(search);
    }

    updateSelectedAuctionHouse(selected: InputSuggestionItem): void {
        AuctionLotEntity.updateSelectedAuctionHouse(selected);
    }

    async mounted(): Promise<void> {
        this.componentCounter++;
    }
}
