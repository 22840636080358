import { makePostRequest } from '@/api/helpers';

export const startLotTrading = async (lotId: number): Promise<unknown> => {
    const result = await makePostRequest(`/api/admin/lot/${lotId}/start/`);

    return result;
};

export const endLotTrading = async (lotId: number): Promise<unknown> => {
    const result = await makePostRequest(`/api/admin/lot/${lotId}/end/`);

    return result;
};

export const registerForAuction = async (auctionId: number): Promise<unknown> => {
    const result = await makePostRequest(`/api/admin/auction/${auctionId}/register/`);

    return result;
};

export const unregisterFromAuction = async (auctionId: number): Promise<unknown> => {
    const result = await makePostRequest(`/api/admin/auction/${auctionId}/unregister/`);

    return result;
};
