
import { Component, Vue } from 'vue-property-decorator';

import FlexLayout from '@/admin/layouts/FlexLayout.vue';

import TableBlock from '@/components/table/TableLots.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';

// import FilterModule from '@/admin/store/filter';
import LotsModule from '@/admin/store/auctions/lots';
import UserModule from '@/admin/store/user';
import RoleModule, { RoleEnum } from '@/admin/store/role';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

import SelectCustom from '@/components/select/SelectCustom.vue';
import Loading from '@/components/Loading.vue';
import { Select } from '@/lib/formFactory/select.interface';

@Component({
    components: {
        TableBlock,
        AppHeaderMain,
        SelectCustom,
        Loading,
        FlexLayout,
    },
})
export default class LotsMain extends Vue {
    get isWriteAllow(): boolean {
        return RoleModule.accessByRouterName[this.$route.name as RoleEnum]?.write.includes(UserModule.role as RoleEnum);
    }

    get settings() {
        return LotsModule.pageSettings;
    }

    get filterSettings() {
        return LotsModule.filter.filterSettings;
    }

    get sellerType(): Select {
        return LotsModule.sellerType;
    }

    get isLoading(): boolean {
        return LotsModule.isLoading;
    }

    get paginationAction(): (number: string) => void {
        return LotsModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: LotsModule.updateItems,
            items: LotsModule.pageSettings.pageAmountItems,
        };
    }

    tableAction(params: any): void {
        if (params.action === 'sort') {
            LotsModule.sort({ field: params.field, sort: params.sort });
        }

        if (params.action === 'checkbox') {
            LotsModule.UPDATE_ROW_CHECKED(params.id);
        }

        if (params.action === 'check-all') {
            LotsModule.toggleCheckAll();
        }
    }

    updateList(): void {
        LotsModule.getList();
    }

    toAdd(): void {
        if (LotsModule.sellerType.current.id === 'all') {
            ResponseHandlerModule.showNotify({ message: 'Выберите тип продавца', type: 'fail' });
            LotsModule.setSellerTypeError();

            return;
        }

        LotsModule.resetSellerTypeError();
        this.$router.push({ name: 'lot_create', params: { sellerType: String(LotsModule.sellerType.current.id) } });
    }

    updateSellerType(params: { id: string; value: string }): void {
        LotsModule.updateSellerType(params);
    }

    async resetFilters(): Promise<void> {
        await LotsModule.clearSort();
        await LotsModule.filter.resetFilter();
        LotsModule.getList();
    }

    mounted(): void {
        LotsModule.init();
    }

    beforeDestroy(): void {
        LotsModule.reset();
    }
}
