
import { Component, Vue } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import TableBlock from '@/components/table/TableLots.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import Loading from '@/components/Loading.vue';

import CatalogsAuctionsLotsModule from '@/admin/store/auctions/catalogsAuctions/lots';
import auctionsEntityModule from '@/admin/store/auctions/catalogsAuctions/entity';
import { getCatalogAuctionCard } from '@/admin/api/catalogAuction';
import { CatalogStatusEnum } from '@/admin/store/auctions/catalogsAuctions/entityCommon';

@Component({
    components: {
        FlexLayout,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
        Loading,
    },
})
export default class AuctionLotsComponent extends Vue {
    isToAddHidden = true;

    get settings() {
        return CatalogsAuctionsLotsModule.pageSettings;
    }

    get filterSettings() {
        return CatalogsAuctionsLotsModule.filter.filterSettings;
    }

    get tabsNav() {
        return auctionsEntityModule.tabsNav;
    }

    get currentTab() {
        return auctionsEntityModule.allTabs.lots.id;
    }

    get isLoading() {
        return CatalogsAuctionsLotsModule.isLoading;
    }

    tableAction(params: any): void {
        if (params.action === 'sort') {
            CatalogsAuctionsLotsModule.sort({ field: params.field, sort: params.sort });

            return;
        }

        if (params.action === 'checkbox') {
            CatalogsAuctionsLotsModule.UPDATE_ROW_CHECKED(params.id);

            return;
        }

        if (params.action === 'check-all') {
            CatalogsAuctionsLotsModule.toggleCheckAll();
        }
    }

    updateList() {
        CatalogsAuctionsLotsModule.getList();
    }

    async resetFilters() {
        await CatalogsAuctionsLotsModule.clearSort();
        CatalogsAuctionsLotsModule.getList();
    }

    toAdd() {
        this.$router.push({
            name: 'lot_create',
            params: { sellerType: 'auction_house', catalogId: this.$route.params.catalogId },
        });
    }

    async mounted(): Promise<void> {
        if (Object.values(this.tabsNav).length === 0) {
            auctionsEntityModule.initTabsItems();
        }

        CatalogsAuctionsLotsModule.init(this.$route.params.catalogId);
        const catalog = await getCatalogAuctionCard(+this.$route.params.catalogId);
        this.isToAddHidden = catalog.status !== CatalogStatusEnum.DRAFT;
    }
}
