import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import { getLotsList } from '@/admin/api/lots';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { formatNumberSpaced } from '@/lib/utils/Utils';

const MODULE_NAME = 'OrderLots';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class OrderLotsModule extends PageBaseModule {
    orderId = 0;

    constructor(module: OrderLotsModule) {
        super(module);
    }

    @Mutation
    SET_ORDER_ID(id: string) {
        this.orderId = Number(id);
    }

    @Mutation
    SET_SORT(sorts: PageSort) {
        this.pageSettings.sort = sorts;
        window.localStorage.auctionOrderLots = JSON.stringify(sorts);
    }

    @Action({ rawError: true })
    async init(orderId: string) {
        this.context.commit('SET_ORDER_ID', orderId);
        this.context.commit(
            'SET_SORT',
            window.localStorage.auctionOrderLots ? JSON.parse(window.localStorage.auctionOrderLots) : {},
        );

        this.getTable();
    }

    @Action({ rawError: true })
    async getTable() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();
        const table: TableApiInterface = await getLotsList(
            1,
            sort,
            `&filters[0][id]=order&filters[0][value]=${this.orderId}`,
            0,
        );

        this.setTable(await this.prepareTable(table));
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        let showActionsColumn = true;
        const visibleColumns = ['name', 'priceSell', 'priceStart'];
        const hiddenColumns = Object.values(table.titles)
            .filter((title) => !visibleColumns.includes(title.id))
            .map((title) => title.id);
        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'priceSell') {
                    cell = cell ? formatNumberSpaced(cell) : cell;
                }

                if (i === 'priceStart') {
                    cell = cell ? formatNumberSpaced(cell) : cell;
                }

                if (i === 'sellerType' && cell === 'painter') {
                    showActionsColumn = false;
                }

                if (i === 'name') {
                    cell = `
                    <a 
                        href="${window.location.origin}/auctions/lot/${row['sellerType']}/${row['id']}"
                        class="cp-table__item-link"
                    >
                        <span class="cp-table-column__accent">
                            ${cell}
                        </span>    
                    </a>`;
                }

                if (i === '_actions') {
                    cell = ['delete'];
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: [...table.titles, { id: '_actions', name: 'add', visible: showActionsColumn }],
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: showActionsColumn ? hiddenColumns : [...hiddenColumns, '_actions'],
        } as TableInterface;
    }

    @Action({ rawError: true })
    setTable(table: TableInterface) {
        this.context.commit('SET_TABLE', table);
    }

    @Action({ rawError: true })
    async sort(params: { field: string; sort: string }) {
        const sortProcessed = await this.sortProcessed(params);

        this.context.commit('SET_SORT', sortProcessed);
        this.context.dispatch('getTable');
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_ORDER_ID', 0);
        this.context.commit('SET_TABLE', {});
    }
}
export default getModule(OrderLotsModule);
