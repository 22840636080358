import { Component, Vue } from 'vue-property-decorator';
import AuctionLotEntity from '@/admin/store/auctions/lots/entity';
import Inputmask from 'inputmask';

import InputDefault from '@/components/input/InputMain.vue';
import InputLocalization from '@/components/input/InputLocalization.vue';
import SelectDefault from '@/components/select/SelectCustom.vue';
import TextareaDefault from '@/components/form/Textarea.vue';
import CheckboxDefault from '@/components/form/CheckboxDefault.vue';
import RadioBtn from '@/components/form/RadioBtn.vue';
import Loading from '@/components/Loading.vue';
import FormBottom from './_formBottom.vue';
import EditorModalLocalization from '@/components/modules/_editorModalLocalization.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import CheckboxCustom from '@/components/form/Checkbox.vue';
import ImageCollection from '@/components/imageInput/Collection.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';
import InputSuggestionsMultiple from '@/components/input/InputSuggestionsMultiple.vue';
import SuggestionsTree from '@/components/suggestions/Tree.vue';
import LotCurrencies from './components/_currencies.vue';
import OuterAddition from '@/components/form/OuterAddition.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { HierarchyCategorySelectItem } from '@/lib/utils/form';
import { numberRegExp, slugRegExp } from '@/constants';
import { Localization, LocalizationParams } from '@/interfaces/localization';

@Component({
    components: {
        InputDefault,
        InputLocalization,
        SelectDefault,
        TextareaDefault,
        CheckboxDefault,
        RadioBtn,
        Loading,
        FormBottom,
        EditorModalLocalization,
        BtnDefault,
        CheckboxCustom,
        ImageCollection,
        InputSuggestions,
        InputSuggestionsMultiple,
        SuggestionsTree,
        LotCurrencies,
        OuterAddition,
    },
})
class LotCardMixin extends Vue {
    get module(): typeof AuctionLotEntity {
        return AuctionLotEntity;
    }

    get model() {
        return AuctionLotEntity.model;
    }

    get isLoading() {
        return AuctionLotEntity.isLoading;
    }

    get modalParams() {
        return AuctionLotEntity.modalParams;
    }

    get cardId() {
        return this.$route.params.lotId;
    }

    get isShowPublish(): boolean {
        return !this.model.isPublished?.list[0].checked;
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    updateName(params: LocalizationParams): void {
        AuctionLotEntity.updateLocalizationField({ ...params, field: 'name' });
    }

    updateSelectedCategory(params: HierarchyCategorySelectItem): void {
        AuctionLotEntity.updateCategoriesList({ selected: params, type: 'category' });
    }

    updateSearchCategory(search: string) {
        AuctionLotEntity.searchCategory(search);
    }

    updateSearchAuthor(search: string) {
        AuctionLotEntity.searchAuthor(search);
    }

    updateSelectedAuthor(selected: InputSuggestionItem) {
        AuctionLotEntity.updateSuggestionsSelectedByType({ type: 'author', selected });
    }

    updateDescription(params: Localization): void {
        AuctionLotEntity.SET_LOCALIZATION_BY_FIELD({ fieldName: 'description', data: params });
    }

    updateSortOrder(value: string) {
        AuctionLotEntity.updateSortOrder(formatRemoveSpaces(value));
    }

    updateLotNumber(value: string) {
        AuctionLotEntity.updateLotNumber(formatRemoveSpaces(value));
    }

    updateIsRightToFollow(params: any) {
        AuctionLotEntity.updateIsRightToFollow(params.bool);
    }

    updateCurrency(params: { id: number; value: string }) {
        AuctionLotEntity.updateCurrency(params);
    }

    updateExchangeRateType(params: any) {
        AuctionLotEntity.updateExchangeRateType(params);
    }

    updateIsPriceReserve(params: any) {
        AuctionLotEntity.updateIsPriceReserve(params.bool);
    }

    updateIsPostauctionSale(params: any) {
        AuctionLotEntity.updateIsPostauctionSale(params.bool);
    }

    updateSizes(value: string) {
        AuctionLotEntity.updateSizes(value);
    }

    updateMaterial(params: LocalizationParams): void {
        AuctionLotEntity.updateLocalizationField({ ...params, field: 'material' });
    }

    updateYear(params: LocalizationParams): void {
        AuctionLotEntity.updateLocalizationField({ ...params, field: 'year' });
    }

    updateEstimateMin(value: string): void {
        AuctionLotEntity.updateModelFieldValue({ field: 'pricesEstimateMin', value: formatRemoveSpaces(value) });
    }

    updateEstimateMax(value: string): void {
        AuctionLotEntity.updateModelFieldValue({ field: 'pricesEstimateMax', value: formatRemoveSpaces(value) });
    }

    updatePriceStart(value: string) {
        AuctionLotEntity.updatePriceStart(formatRemoveSpaces(value));
    }

    updatePriceMin(value: string) {
        AuctionLotEntity.updatePriceMin(formatRemoveSpaces(value));
    }

    updatePriceReserve(value: string) {
        AuctionLotEntity.updatePriceReserve(formatRemoveSpaces(value));
    }

    updatePriceBuyNow(value: string): void {
        AuctionLotEntity.updatePriceBuyNow(formatRemoveSpaces(value));
    }

    handleImage(params: { id: string; file: File, cropp: boolean }) {
        AuctionLotEntity.updatePhotoFileById(params);
    }

    addImages(files: File[]) {
        if (files) {
            AuctionLotEntity.addImages(files);
        }
    }

    updateMainPhoto(id: string) {
        AuctionLotEntity.updateMainPhoto(id);
    }

    updateImageSort(params: { moveFrom: number; moveTo: number }) {
        AuctionLotEntity.updateImageSort(params);
    }

    removeImage(id: string) {
        AuctionLotEntity.removeImageById(id);
    }

    updateIsHideImage(params: { bool: boolean }): void {
        AuctionLotEntity.updateIsHideImage(params.bool);
    }

    updateIsHidePrice(params: { bool: boolean }): void {
        AuctionLotEntity.updateIsHidePrice(params.bool);
    }

    updateSlug(value: string): void {
        AuctionLotEntity.updateModelFieldValue({ field: 'slug', value });
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            const input = item.getElementsByTagName('input')[0];
            Inputmask.remove(input);
            settingsMask.mask(input);
        }

        const settingsMaskSlug = new Inputmask({ regex: slugRegExp, showMaskOnHover: false });
        const inputsSlug = this.$el.getElementsByClassName('j_slug_mask_wrapper');

        for (const item of Object.values(inputsSlug)) {
            settingsMaskSlug.mask(item.getElementsByTagName('input')[0]);
        }
    }

    sendPublish(): void {
        AuctionLotEntity.sendPublish();
    }

    send(publish?: boolean | Event): void {
        const isPublish = typeof publish === 'boolean' ? publish : undefined;
        if (this.$route.params.lotId) {
            AuctionLotEntity.sendEdit(isPublish);

            return;
        }

        if (this.$route.params.catalogId) {
            AuctionLotEntity.sendAdd({
                redirect: { name: 'auction_lots', params: { catalogId: this.$route.params.catalogId } },
                publish: isPublish,
            });

            return;
        }

        AuctionLotEntity.sendAdd({ redirect: { name: 'lots' }, publish: isPublish });
    }

    sendWithdraw(): void {
        AuctionLotEntity.sendWithdraw(this.$route.params.lotId);
    }

    openModal() {
        AuctionLotEntity.toggleModal(true);
    }

    closeModal() {
        AuctionLotEntity.toggleModal(false);
    }

    init(): void {
        this.initMask();
    }

    mounted(): void {
        this.init();
    }
}
export default LotCardMixin;
