
import { Prop, Component, Vue } from 'vue-property-decorator';

import BtnDefault from '@/components/BtnDefault.vue';
import SelectBlock from '@/components/select/SelectCustom.vue';

import SelectEntity from '@/lib/formFactory/selectEntity';
import { Select, SelectItem } from '@/lib/formFactory/select.interface';

import { LotInterface, LOT_EXCLUDE_STATUSES } from './_bidding.vue';
import { LotApiType } from '@/interfaces/catalogPublic.interface';
import { LotStatusEnum } from '@/admin/store/auctions/lots/entity';

@Component({
    components: {
        BtnDefault,
        SelectBlock,
    },
})
export default class BiddingActionsComponent extends Vue {
    @Prop({ required: true }) isRunning!: string;
    @Prop({ required: true }) lot!: LotInterface | null;
    @Prop({ required: true }) lotId!: number | null;
    @Prop({ required: true }) lots!: LotApiType[];
    @Prop({ required: true, default: false }) bidAutoApprove!: boolean;

    strings: Record<string, string> = {
        finishAndGoNext: 'Закрыть торг по лоту',
        startBidding: 'Начать торги',
        finishBidding: 'Закончить торги',
        startLotBidding: 'Начать торги по лоту',
        lastChance: 'Последний шанс!',
        goPrev: 'Предыдущий лот',
        goNext: 'Следующий лот',
        removeFromTrading: 'Снять лот с торгов',
        enableAutoapprove: 'Включить автоматическое одобрение ставок',
        disableAutoapprove: 'Отключить автоматическое одобрение ставок',
    };
    lotSelectModel: Select | null = null;

    get isShowStartBidding(): boolean {
        return (
            !this.isLotBidding &&
            !LOT_EXCLUDE_STATUSES.includes(this.lot?.status as LotStatusEnum) &&
            !!this.lots.length
        );
    }

    get isAllowLotChange(): boolean {
        return !this.isLotBidding && this.lotsList.length > 0;
    }

    get isLotBidding(): boolean {
        return this.lot?.status === 'online_auction';
    }

    get lotsList(): SelectItem[] {
        return this.lots
            .filter((row) => row.id !== this.lotId)
            .map((row) => {
                return {
                    id: row.id as number,
                    value: `#${row.number} ${row.name}`,
                };
            });
    }

    get currentLotIndex(): number {
        return this.lots.findIndex((lot) => lot.id === this.lotId);
    }

    get prevLotId(): number | null {
        return this.lots[this.currentLotIndex - 1]?.id;
    }

    get nextLotId(): number | null {
        return this.lots[this.currentLotIndex + 1]?.id;
    }

    goToLot(lot: SelectItem): void {
        if (this.lotSelectModel === null) {
            return;
        }

        this.lotSelectModel.current = lot;
        this.$emit('go', { action: 'to', data: lot.id });
    }

    goPrev(): void {
        const newCurrent = this.lotsList.find((lot) => lot.id === this.prevLotId);
        if (this.lotSelectModel && newCurrent) {
            this.lotSelectModel.current = newCurrent;
        }

        this.$emit('go', { action: 'to', data: this.prevLotId });
    }

    goNext(): void {
        const newCurrent = this.lotsList.find((lot) => lot.id === this.nextLotId);
        if (this.lotSelectModel && newCurrent) {
            this.lotSelectModel.current = newCurrent;
        }

        this.$emit('go', { action: 'to', data: this.nextLotId });
    }

    toggleAutoapprove(): void {
        this.$emit('click', {
            type: 'operator',
            action: 'autoApprove',
            auctionId: this.$route.params.catalogId,
            data: !this.bidAutoApprove,
        });
    }

    sendLastChance(): void {
        this.$emit('click', { type: 'operator', action: 'lastChance', lotId: this.lotId });
    }

    startBidding(): void {
        this.$emit('click', { type: 'operator', action: 'auctionStart', auctionId: this.$route.params.catalogId });
    }

    finishBidding(): void {
        this.$emit('click', { type: 'operator', action: 'auctionEnd', auctionId: this.$route.params.catalogId });
    }

    startLotBidding(): void {
        this.$emit('click', { type: 'operator', action: 'newLot', lotId: this.lotId });
    }

    finishLotBidding(): void {
        this.$emit('click', { type: 'operator', action: 'endLot', lotId: this.lotId });
    }

    cancelBidding(): void {
        this.$emit('click', { type: 'operator', action: 'cancelLot', lotId: this.lotId });
    }

    initModel(): void {
        const lotSelectEntity = new SelectEntity('lots');
        lotSelectEntity.setTitle('Перейти к лоту');

        this.lotSelectModel = lotSelectEntity.entity[lotSelectEntity.entityName];
    }

    async created(): Promise<void> {
        await this.initModel();
    }
}
