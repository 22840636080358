
import InputBlock from '@/components/input/InputMain.vue';
import BtnDefault from '@/components/buttons/BtnSubmitFake/BtnSubmitFake.vue';

import { Input } from '@/lib/formFactory/input.interface';
import InputEntity from '@/lib/formFactory/inputEntity';
import Inputmask from 'inputmask';

import { Prop, Component, Vue } from 'vue-property-decorator';
import { numberRegExp } from '@/constants';

@Component({
    components: {
        InputBlock,
        BtnDefault,
    },
})
export default class BiddingManual extends Vue {
    @Prop({ required: true }) readonly lotId!: number;

    btnText = 'Добавить ставку';
    inputText = 'Добавить ставку вручную';
    bidModel: Input | null = null;

    updateBidValue(value: string): void {
        if (!this.bidModel) {
            return;
        }

        this.bidModel.value = value;
    }

    makeManualBet(): void {
        if (!this.bidModel?.value) {
            return;
        }

        this.$emit('click', { type: 'operator', action: 'newBid', lotId: this.lotId, bid: +this.bidModel.value });
    }

    initModel(): void {
        const bidEntity = new InputEntity('bid');
        bidEntity.setTitle(this.inputText);

        this.bidModel = bidEntity.entity[bidEntity.entityName];
    }

    initInputmask(): void {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputs = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputs)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    async mounted(): Promise<void> {
        await this.initModel();
        this.initInputmask();
    }
}
