
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import TableBlock from '@/components/table/TableBidding.vue';
import { TableApiInterface, TableInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import { getBidsList } from '@/admin/api/bids';
import { formatNumberSpaced, generateFilter, generateSort, getMapKeyByValue } from '@/lib/utils/Utils';
import WebsocketsModule from '@/admin/store/websockets';
import { BidType, SocketBidParams } from '@/interfaces/catalogPublic.interface';
import { WS } from '@/lib/utils/websockets';
import UserModule from '@/admin/store/user';
import SiteModule from '@/admin/store/site';
import { showError } from '@/lib/utils/message';
import Loading from '@/components/Loading.vue';
import { EventBus } from '@/lib/EventBus';

interface BidBackendInterface extends Record<string, any> {
    id: number;
    bid: number;
    bidType: string;
    createdAt: string;
    isApproved: boolean | null;
    isLast: boolean;
    user: string;
    userId: number;
    _actions: string[];
}

interface BidTableRowType {
    id: number;
    createdAt: string;
    bid: string;
    approved: boolean | null;
    _actions: (string | undefined)[];
}

export enum ApproveEnum {
    APPROVE = 'approve',
    DISAPPROVE = 'disapprove',
    CANCEL = 'cancel',
}

enum BidTypeEnum {
    REGULAR = 'Пользователь',
    OPERATOR = 'Оператор',
}

const approveMap = new Map([
    [null, ApproveEnum.APPROVE],
    [true, ApproveEnum.DISAPPROVE],
    [false, ApproveEnum.CANCEL],
]);

const SUCCESS_STATUS = 'Success';
const APPROVED_FIELD_NAMES = ['isApproved', 'approved'];

const formatBackendBid = (bid: BidBackendInterface | BidType) => {
    const approvedFieldName = APPROVED_FIELD_NAMES.find((name) => name in bid);
    const approved = bid[approvedFieldName as string];

    return {
        id: bid.id,
        createdAt: bid.createdAt,
        bidType: bid.bidType === 'operator' ? BidTypeEnum.OPERATOR : BidTypeEnum.REGULAR,
        bid: formatNumberSpaced(bid.bid),
        approved,
        _actions: [],
    };
};

function sortBidsByCreatedAt(a: BidTableRowType, b: BidTableRowType): number {
    const aCreatedAt = new Date(a.createdAt as string).getTime();
    const bCreatedAt = new Date(b.createdAt as string).getTime();

    if (aCreatedAt > bCreatedAt) {
        return -1;
    }
    if (aCreatedAt < bCreatedAt) {
        return 1;
    }

    return 0;
}

@Component({
    components: {
        TableBlock,
        Loading,
    },
})
export default class AuctionBiddingTableComponent extends Vue {
    titles: TableTitle[] = [
        {
            id: 'id',
            name: 'Id',
            visible: false,
        },
        {
            id: 'createdAt',
            name: 'Дата и время ставки',
            visible: true,
        },
        {
            id: 'bidType',
            name: 'Покупатель',
            visible: true,
        },
        {
            id: 'bid',
            name: 'Сумма',
            visible: true,
        },
        {
            id: 'approved',
            name: '',
            visible: false,
        },
        {
            id: '_actions',
            name: '',
            visible: true,
        },
    ];
    bids: TableInterface | null = null;
    wsLoaded = false;
    pendingChange: Record<string, string | number> | null = null;
    isLoading = false;

    @Prop() lotId!: number;

    @Watch('lotId')
    async onLotIdChange(): Promise<void> {
        this.updateBids();
    }

    @Watch('websocket')
    onWsChange(val: WS): void {
        if (val) {
            this.setWs();
        } else {
            this.wsLoaded = false;
        }
    }

    get websocket(): WS | null {
        return WebsocketsModule.ws;
    }

    get lastApprovedBidId(): number {
        if (!this.bids) {
            return 0;
        }

        return this.bids.rows.find((row) => row.approved)?.id ?? 0;
    }

    get bidRows(): BidTableRowType[] | undefined {
        return (
            this.bids?.rows.map((row) => {
                return {
                    ...row,
                    _actions:
                        row.approved === true && row.id !== this.lastApprovedBidId ? [] : [approveMap.get(row.approved)],
                } as BidTableRowType;
            }) || []
        );
    }

    get userId(): number | null {
        return UserModule.id;
    }

    prepareList(list: TableApiInterface): TableInterface {
        const hiddenColumns = this.titles.filter((title) => !title.visible).map((title) => title.id);
        const rows = list.rows.map((row) => {
            return formatBackendBid(row as BidBackendInterface);
        });

        return {
            titles: this.titles,
            rows: rows.sort(sortBidsByCreatedAt),
            sortFields: list.sortFields,
            totalItems: list.totalItems,
            pagination: list.pagination,
            filters: list.filters,
            hiddenColumns: hiddenColumns,
        };
    }

    tableAction(params: { action: string; id: number; _: unknown }): void {
        if (params.action === ApproveEnum.APPROVE || params.action === ApproveEnum.CANCEL) {
            this.pendingChange = { id: params.id, status: ApproveEnum.DISAPPROVE };
            this.$emit('click', { type: 'operator', action: 'bidApprove', bidId: params.id });

            return;
        }

        if (params.action === ApproveEnum.DISAPPROVE) {
            this.pendingChange = { id: params.id, status: ApproveEnum.CANCEL };
            this.$emit('click', { type: 'operator', action: 'bidCancel', bidId: params.id });
        }
    }

    async updateBids(): Promise<void> {
        if (!this.lotId) {
            return;
        }

        this.isLoading = true;
        const result = await getBidsList(
            1,
            generateSort({ id: 'createdAt', value: 'DESC' }),
            generateFilter({ id: 'lot', value: `${this.lotId}` }),
        );
        this.bids = await this.prepareList(result);
        this.isLoading = false;
    }

    updateBid(data: SocketBidParams): void {
        if (data.lotId !== this.lotId) {
            return;
        }

        if (!this.bids) {
            return;
        }

        this.bids.rows.unshift(formatBackendBid(data.newBid[0]));
    }

    setWs(): void {
        if (!this.wsLoaded && this.websocket) {
            this.initUserSubscription();

            this.wsLoaded = true;
        }
    }

    userOperatorSubscriber(data: string): void {
        SiteModule.SET_IS_BLOCK(false);
        if (data === SUCCESS_STATUS) {
            this.updateBidStatus();

            return;
        }

        showError(data);
    }

    initUserSubscription(): void {
        if (!this.userId) {
            return;
        }

        EventBus.$emit('update-user-subscriber', { operator: this.userOperatorSubscriber });
    }

    updateBidStatus(): void {
        if (!this.pendingChange?.id || !this.bids) {
            return;
        }

        this.bids.rows = this.bids.rows.map((row) => {
            if (row.id !== this.pendingChange?.id) {
                return row;
            }

            let approved = getMapKeyByValue(approveMap, this.pendingChange?.status as string) || false;

            return {
                ...row,
                approved,
            };
        });
    }

    created(): void {
        this.updateBids();
        this.setWs();
    }
}
