
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import OrderLotsModule from '@/admin/store/auctions/orders/lots';
import OrderEntity from '@/admin/store/auctions/orders/entity';
import { OrdersModelInterface } from '@/interfaces/models/orders.interface';

import TableBlock from '@/components/table/Table.vue';
import WarningModal from '@/components/modules/Warning.vue';
import AddLotModal from './_addLotTableModal.vue';
import Loading from '@/components/Loading.vue';

@Component({
    components: {
        TableBlock,
        WarningModal,
        AddLotModal,
        Loading,
    },
})
export default class OrderLotsTemplate extends Vue {
    @Prop({ required: true }) readonly updateCounter!: number;

    @Watch('updateCounter')
    onUpdateCounterChange(): void {
        OrderLotsModule.getTable();
    }

    lotIdToDelete = 0;

    warningText = {
        yes: 'Удалить',
        description: `Вы уверены, что хотите удалить лот из заказа?`,
    };

    get settings() {
        return OrderLotsModule.pageSettings;
    }

    get model(): OrdersModelInterface {
        return OrderEntity.model;
    }

    get status(): string | number {
        return this.model?.status?.current?.id;
    }

    get isUnderConsideration(): boolean {
        return this.status === 'under_consideration';
    }

    get isLoading() {
        return OrderLotsModule.isLoading;
    }
    
    get tableTitles() {
        const titles = [...this.settings.table.titles];

        if (!this.isUnderConsideration) {
            titles.pop();
        }

        return titles;
    }

    tableAction(params: any) {
        if (params.action === 'delete') {
            this.lotIdToDelete = params.id;
        }

        if (params.action === 'sort') {
            OrderLotsModule.sort({ field: params.field, sort: params.sort });
        }
    }

    async removeLot() {
        await OrderEntity.removeLot(this.lotIdToDelete);
        this.lotIdToDelete = 0;
        OrderLotsModule.getTable();
    }

    no() {
        this.lotIdToDelete = 0;
    }

    closeModal() {
        this.lotIdToDelete = 0;
    }

    addLot() {
        this.$emit('open-add-lot');
    }

    mounted() {
        if (this.$route.params.orderId) {
            OrderLotsModule.init(this.$route.params.orderId);
        }
    }

    beforeDestroy() {
        OrderLotsModule.reset();
    }
}
