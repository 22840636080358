
import { Vue, Component } from 'vue-property-decorator';
import OrderEntity from '@/admin/store/auctions/orders/entity';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import UserModule from '@/admin/store/user';

import Loading from '@/components/Loading.vue';
import TextareaBlock from '@/components/form/Textarea.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import { SelectItem } from '@/lib/formFactory/select.interface';
import InputBlock from '@/components/form/InputMain.vue';
import StatusHistory from './_statusHistory.vue';
import LotsBlock from './_lots.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import AddLotModal from './_addLotTableModal.vue';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { TableInterface } from '@/lib/layouts/page/table.interface';
import { OrdersModelInterface } from '@/interfaces/models/orders.interface';

@Component({
    components: {
        Loading,
        TextareaBlock,
        SelectCustom,
        InputBlock,
        BtnSubmit,
        BtnDefault,
        StatusHistory,
        LotsBlock,
        AddLotModal,
    },
})
export default class OrderCardComponent extends Vue {
    isAddLotModalShow = false;
    orderLotsUpdateCounter = 0;

    get isLoading(): boolean {
        return OrderEntity.isLoading;
    }

    get model(): OrdersModelInterface {
        return OrderEntity.model;
    }

    get statusHistoryTable(): TableInterface | null {
        return OrderEntity.statusHistoryTable;
    }

    get orderId(): number | null {
        return OrderEntity.orderId;
    }

    get isSuperAdmin() {
        return UserModule.isSuperAdmin;
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    updateStatus(selected: SelectItem): void {
        OrderEntity.updateStatus(selected);
    }

    updateComment(value: string): void {
        OrderEntity.updateComment(value);
    }

    async updateCostDelivery(value: string): Promise<void> {
        await OrderEntity.updateCostDelivery(formatRemoveSpaces(value));
        OrderEntity.updateCostTotal();
    }

    returnBefore(): void {
        this.$router.push({ name: 'orders' });
    }

    async send(): Promise<void> {
        const isError = await OrderEntity.validate();
        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        OrderEntity.sendEdit();
    }

    openAddLotModal(): void {
        this.isAddLotModalShow = true;
    }

    closeAddLotModal(): void {
        this.orderLotsUpdateCounter++;
        this.isAddLotModalShow = false;
    }

    mounted(): void {
        OrderEntity.init(this.$route.params.orderId);
    }

    beforeDestroyed(): void {
        OrderEntity.reset();
    }
}
