/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';
import UserModule from '@/admin/store/user';
import { RoleEnum } from '@/admin/store/role';

export default class LotsFilter {
    isRoleSuperAdmin: boolean;
    filterHandlers = {
        update: 'LotsFilter/updateFilter',
        reset: 'LotsFilter/resetFilter',
        resetState: 'LotsFilter/resetFilterState',
        removeSelected: 'LotsFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        this.isRoleSuperAdmin = UserModule.role === RoleEnum.ROLE_SUPER_ADMIN;

        const filter = new FilterFactory();
        filter.createSearchEntity('name');
        filter.setPlaceholder('name', 'По названию');
        filter.setName('name', 'search_name');
        filter.setPreviewName('name', 'Название');
        filter.setIconClassName('name', 'icon-book');
        filter.setClassName('name', `${this.isRoleSuperAdmin ? 'col-1-' : ''}row-1`);
        filter.setAction('name', 'lots/updateSearch');

        if (this.isRoleSuperAdmin) {
            filter.createSuggestionsEntity('sellerAuctionHouse');
            filter.setPlaceholder('sellerAuctionHouse', 'Начните вводить название АД');
            filter.setName('sellerAuctionHouse', 'suggestions_auction_house');
            filter.setPreviewName('sellerAuctionHouse', 'АД');
            filter.setSearchAction('sellerAuctionHouse', 'lots/updateAuctionSuggestionsValue');
            filter.setAction('sellerAuctionHouse', 'lots/updateSuggestionsSelected');
            filter.setIconClassName('sellerAuctionHouse', 'icon-store');
            filter.setClassName('sellerAuctionHouse', 'col-2-row-1');

            filter.createSuggestionsEntity('sellerGallery');
            filter.setPlaceholder('sellerGallery', 'Начните вводить название галереи');
            filter.setName('sellerGallery', 'suggestions_gallery');
            filter.setPreviewName('sellerGallery', 'Галерея');
            filter.setIconClassName('sellerGallery', 'icon-bank');
            filter.setClassName('sellerGallery', 'col-2-row-2');
            filter.setAction('sellerGallery', 'lots/updateSuggestionsSelected');
            filter.setSearchAction('sellerGallery', 'lots/updateGallerySuggestionsValue');

            filter.createSuggestionsEntity('sellerPainter');
            filter.setPlaceholder('sellerPainter', 'Начните вводить имя автора');
            filter.setName('sellerPainter', 'suggestions_painter');
            filter.setPreviewName('sellerPainter', 'Автор');
            filter.setIconClassName('sellerPainter', 'icon-pencil');
            filter.setClassName('sellerPainter', 'col-2-row-3');
            filter.setAction('sellerPainter', 'lots/updateSuggestionsSelected');
            filter.setSearchAction('sellerPainter', 'lots/updatePainterSuggestionsValue');
        }

        filter.createDateIntervalPickerEntity('datetimePosting');
        filter.setName('datetimePosting', 'picker_date');
        filter.setTitle('datetimePosting', 'Время разм.');
        filter.setPreviewName('datetimePosting', 'Вр. разм.');
        filter.setDateIntervalList('datetimePosting', {
            startDate: {
                key: 'datetimePostingStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'lots/updateStartDate',
            },
            endDate: {
                key: 'datetimePostingEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'lots/updateEndDate',
            },
        });
        filter.setClassName('datetimePosting', `${this.isRoleSuperAdmin ? 'col-1-' : ''}row-2`);

        filter.createDateIntervalPickerEntity('dateTimeStartTrad');
        filter.setName('dateTimeStartTrad', 'picker_date');
        filter.setTitle('dateTimeStartTrad', 'Время старта');
        filter.setPreviewName('dateTimeStartTrad', 'Вр. старта');
        filter.setDateIntervalList('dateTimeStartTrad', {
            startDate: {
                key: 'dateTimeStartTradStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'lots/updateStartDate',
            },
            endDate: {
                key: 'dateTimeStartTradEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'lots/updateEndDate',
            },
        });
        filter.setClassName('dateTimeStartTrad', `${this.isRoleSuperAdmin ? 'col-1-' : ''}row-3`);

        this.filterModel = filter.filterModel;
    }
}
