
import Layout from '@/admin/layouts/Default.vue';
import MainBlock from './Main.vue';

export default {
    components: {
        Layout,
        MainBlock,
    },
};
