import API from '@/admin/config/api-routers';
import { makeDeleteRequest, makeGetRequest } from '@/api/helpers';

export const getBidsList = async (pageCurrent: number, sort: string, filter: string) => {
    if (sort) {
        sort = `&${sort}`;
    }
    if (filter) {
        filter = `&${filter}`;
    }

    const result = await makeGetRequest(API.bids.list + `?page=${pageCurrent}${sort}${filter}`);

    return result.data.result.table;
};

export const cancelBid = async (bidId: number) => {
    const result = await makeDeleteRequest(`${API.bids.cancel}${bidId}/`);

    return result;
};
