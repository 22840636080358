/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';
import UserModule from '@/admin/store/user';
import { RoleEnum } from '../../role';

export default class ShopsFilter {
    isRoleSuperAdmin = false;
    filterHandlers = {
        update: 'CatalogsAuctionsFilter/updateFilter',
        reset: 'CatalogsAuctionsFilter/resetFilter',
        resetState: 'CatalogsAuctionsFilter/resetFilterState',
        removeSelected: 'CatalogsAuctionsFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        this.isRoleSuperAdmin = UserModule.role === RoleEnum.ROLE_SUPER_ADMIN;

        const filter = new FilterFactory();
        filter.createSuggestionsEntity('name');
        filter.setPlaceholder('name', 'Начните вводить название каталога');
        filter.setName('name', 'suggestions_name');
        filter.setPreviewName('name', 'Название');
        filter.setAction('name', 'catalogsAuctions/updateSuggestionsSelectedFilter');
        filter.setSearchAction('name', 'catalogsAuctions/updateSuggestionsCatalogSearchFilter');
        filter.setIconClassName('name', 'icon-book');
        filter.setClassName('name', 'col-1-row-1');

        if (this.isRoleSuperAdmin) {
            filter.createSuggestionsEntity('sellerAuctionHouseName');
            filter.setPlaceholder('sellerAuctionHouseName', 'Начните вводить название АД');
            filter.setName('sellerAuctionHouseName', 'suggestions_seller_auction_house_name');
            filter.setPreviewName('sellerAuctionHouseName', 'АД');
            filter.setAction('sellerAuctionHouseName', 'catalogsAuctions/updateSuggestionsSelectedFilter');
            filter.setSearchAction(
                'sellerAuctionHouseName',
                'catalogsAuctions/updateSuggestionsAuctionHouseSearchFilter',
            );
            filter.setIconClassName('sellerAuctionHouseName', 'icon-store');
            filter.setClassName('sellerAuctionHouseName', 'col-1-row-2');
        }

        filter.createCheckboxEntity('withRemoved');
        filter.setName('withRemoved', 'checkbox_withRemoved');
        filter.setTitle('withRemoved', 'Показывать удалённые');
        filter.setPreviewName('withRemoved', 'Удалённые');
        filter.setAction('withRemoved', 'catalogsAuctions/updateCheckboxFilter');
        filter.setIconClassName('withRemoved', 'icon-book');
        filter.setIgnoreNull('withRemoved', true);
        filter.setList('withRemoved', [
            {
                id: 'withRemoved',
                name: 'Показывать удалённые',
                value: 'withRemoved',
                checked: false,
            },
        ]);
        filter.setClassName('withRemoved', this.isRoleSuperAdmin ? 'col-1-row-3' : 'col-1-row-2');

        filter.createSelectEntity('status');
        filter.setTitle('status', 'По статусу');
        filter.setName('status', 'select_status');
        filter.setPreviewName('status', 'Статус');
        filter.setInitAction('status', 'catalogsAuctions/initStatuses');
        filter.setAction('status', 'catalogsAuctions/updateSelect');
        filter.setIconClassName('status', 'icon-status');
        filter.setClassName('status', 'col-2-row-1');

        filter.createSelectEntity('auctionType');
        filter.setTitle('auctionType', 'По виду торгов');
        filter.setName('auctionType', 'select_auction_type');
        filter.setPreviewName('auctionType', 'Вид торгов');
        filter.setList('auctionType', [
            { id: 'auto_in_order', value: 'Автоматические по порядку' },
            { id: 'auto_one_time', value: 'Автоматические одновременно' },
            { id: 'manual', value: 'С ведущим' },
        ]);
        filter.setAction('auctionType', 'catalogsAuctions/updateSelect');
        filter.setIconClassName('auctionType', 'icon-compare');
        filter.setClassName('auctionType', 'col-2-row-2');

        filter.createSuggestionsEntity('city');
        filter.setPlaceholder('city', 'Начните вводить название города');
        filter.setName('city', 'suggestions_city');
        filter.setPreviewName('city', 'Город');
        filter.setAction('city', 'catalogsAuctions/updateSuggestionsSelectedFilter');
        filter.setSearchAction('city', 'catalogsAuctions/updateSuggestionsCitySearchFilter');
        filter.setIconClassName('city', 'icon-city');
        filter.setClassName('city', 'col-2-row-3');

        this.filterModel = filter.filterModel;
    }
}
