/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class CatalogAuctionLotsFilter {
    filterHandlers = {
        update: 'CatalogsAuctionsLotsFilter/updateFilter',
        reset: 'CatalogsAuctionsLotsFilter/resetFilter',
        resetState: 'CatalogsAuctionsLotsFilter/resetFilterState',
        removeSelected: 'CatalogsAuctionsLotsFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();
        filter.createSearchEntity('name');
        filter.setPlaceholder('name', 'По названию');
        filter.setName('name', 'search_name');
        filter.setPreviewName('name', 'Название');
        filter.setIconClassName('name', 'icon-book');
        filter.setClassName('name', 'col-1-row-1');
        filter.setAction('name', 'catalogsAuctionsLots/updateSearch');

        filter.createSelectEntity('sellerType');
        filter.setTitle('sellerType', 'По типу продавца');
        filter.setName('sellerType', 'select_seller_type');
        filter.setPreviewName('sellerType', 'Тип продавца');
        filter.setInitAction('sellerType', 'catalogsAuctionsLots/initSellerTypes');
        filter.setAction('sellerType', 'catalogsAuctionsLots/updateSelect');
        filter.setIconClassName('sellerType', 'icon-store');
        filter.setClassName('sellerType', 'col-1-row-2');

        filter.createSuggestionsEntity('sellerAuctionHouse');
        filter.setPlaceholder('sellerAuctionHouse', 'Начните вводить название АД');
        filter.setName('sellerAuctionHouse', 'suggestions_auction_house');
        filter.setPreviewName('sellerAuctionHouse', 'АД');
        filter.setAction('sellerAuctionHouse', 'catalogsAuctionsLots/updateSuggestionsSelectedFilter');
        filter.setSearchAction('sellerAuctionHouse', 'catalogsAuctionsLots/updateSuggestionsSearchFilter');
        filter.setIconClassName('sellerAuctionHouse', 'icon-store');
        filter.setClassName('sellerAuctionHouse', 'col-2-row-1');

        filter.createSuggestionsEntity('sellerGallery');
        filter.setPlaceholder('sellerGallery', 'Начните вводить название галереи');
        filter.setName('sellerGallery', 'suggestions_gallery');
        filter.setPreviewName('sellerGallery', 'Галерея');
        filter.setAction('sellerGallery', 'catalogsAuctionsLots/updateSuggestionsSelectedFilter');
        filter.setSearchAction('sellerGallery', 'catalogsAuctionsLots/updateSuggestionsSearchFilter');
        filter.setIconClassName('sellerGallery', 'icon-bank');
        filter.setClassName('sellerGallery', 'col-2-row-2');

        filter.createSuggestionsEntity('sellerPainter');
        filter.setPlaceholder('sellerPainter', 'По автору');
        filter.setName('sellerPainter', 'suggestions_painter');
        filter.setPreviewName('sellerPainter', 'Автор');
        filter.setAction('sellerPainter', 'catalogsAuctionsLots/updateSuggestionsSelectedFilter');
        filter.setSearchAction('sellerPainter', 'catalogsAuctionsLots/updateSuggestionsSearchFilter');
        filter.setIconClassName('sellerPainter', 'icon-pencil');
        filter.setClassName('sellerPainter', 'col-2-row-3');

        filter.createDateIntervalPickerEntity('datetimePosting');
        filter.setName('datetimePosting', 'picker_date');
        filter.setTitle('datetimePosting', 'Время разм.');
        filter.setPreviewName('datetimePosting', 'Вр. разм.');
        filter.setDateIntervalList('datetimePosting', {
            startDate: {
                key: 'datetimePostingStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'catalogsAuctionsLots/updateStartDate',
            },
            endDate: {
                key: 'datetimePostingEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'catalogsAuctionsLots/updateEndDate',
            },
        });
        filter.setClassName('datetimePosting', 'col-1-row-3');

        filter.createDateIntervalPickerEntity('dateTimeStartTrad');
        filter.setName('dateTimeStartTrad', 'picker_date');
        filter.setTitle('dateTimeStartTrad', 'Время старта');
        filter.setPreviewName('dateTimeStartTrad', 'Вр. старта');
        filter.setDateIntervalList('dateTimeStartTrad', {
            startDate: {
                key: 'dateTimeStartTradStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'catalogsAuctionsLots/updateStartDate',
            },
            endDate: {
                key: 'dateTimeStartTradEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'catalogsAuctionsLots/updateEndDate',
            },
        });
        filter.setClassName('dateTimeStartTrad', 'col-1-row-4');

        this.filterModel = filter.filterModel;
    }
}
