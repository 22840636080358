
import { Vue, Component, Prop } from 'vue-property-decorator';
import { StatusHistoryListInterface } from '@/interfaces/orders.interface';

import TableBlock from '@/components/table/Table.vue';

@Component({
    components: {
        TableBlock,
    },
})
export default class OrderStatusHistoryComponent extends Vue {
    @Prop({ required: true }) readonly data!: StatusHistoryListInterface;
}
