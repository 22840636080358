import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/admin/store';
import BidsBaseModule from '@/admin/store/bids';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { Filter } from '@/lib/layouts/page/filter.interface';
import filterEntity from './lotBidsAuctionHouseFilter';
import filterModel from './lotBidsAuctionHouseFilterEntity';
import LotEntity from './entity';
import { getLotCard } from '@/admin/api/lots';

import { FilterDateParamsInterface, FilterSearchParamsInterface } from '@/lib/layouts/page/filterParams.interface';
import { getBidsList } from '@/admin/api/bids';

const MODULE_NAME = 'lotBidsAuctionHouse';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class LotBidsAuctionHouseModule extends BidsBaseModule {
    filter: Filter;
    allowBidCancelStatuses: string[];

    constructor(module: LotBidsAuctionHouseModule) {
        super(module);

        this.allowBidCancelStatuses = ['pre_auction', 'online_auction'];

        const filter = new filterModel();
        this.filter = getModule(filterEntity);
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterName('lotBidsAuctionHouse');
        this.filter.setFilterHandlers(filter.filterHandlers);
        this.filter.setFilterModel(filter.filterModel);
    }

    @Mutation
    SET_SORT(sorts: PageSort) {
        this.pageSettings.sort = sorts;
        window.localStorage.lotBidsAuctionHouseSort = JSON.stringify(sorts);
    }

    @Action({ rawError: true })
    async init(lotId: string) {
        this.context.commit('SET_ID', lotId);
        this.context.commit(
            'SET_SORT',
            window.localStorage.lotBidsAuctionHouseSort ? JSON.parse(window.localStorage.lotBidsAuctionHouseSort) : {},
        );

        await this.filter.init();

        this.context.dispatch('getList');
    }

    @Action({ rawError: true })
    async getList() {
        this.context.commit('SET_IS_LOADING', true);

        let allowBidCancel = false;
        const sort = await this.context.dispatch('getSortForRequest');
        const filter = `&filters[0][id]=lot&filters[0][value]=${this.id}${this.filter.filterSettings.filter}`;
        const promises = [getBidsList(this.pageSettings.pageCurrent, `&${sort}`, filter)];

        if (String(LotEntity.cardId) === this.id) {
            allowBidCancel = this.allowBidCancelStatuses.includes(LotEntity.model.status);
        } else {
            promises.push(getLotCard(this.id));
        }

        const [result, lot] = await Promise.all(promises);
        allowBidCancel = lot ? this.allowBidCancelStatuses.includes(lot.status) : allowBidCancel;

        this.context.dispatch('setList', await this.prepareTable({ table: result, allowBidCancel }));
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateStartDate(params: FilterDateParamsInterface) {
        await this.filter.updateStartDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateEndDate(params: FilterDateParamsInterface) {
        await this.filter.updateEndDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    clearSort() {
        this.context.commit('SET_SORT', {});
    }
}
export default getModule(LotBidsAuctionHouseModule);
