/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';
import { RoleEnum } from '@/admin/store/role';
import UserModule from '@/admin/store/user';

export default class ShopsFilter {
    isRoleSuperAdmin: boolean;
    filterHandlers = {
        update: 'AuctionsOrdersFilter/updateFilter',
        reset: 'AuctionsOrdersFilter/resetFilter',
        resetState: 'AuctionsOrdersFilter/resetFilterState',
        removeSelected: 'AuctionsOrdersFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        this.isRoleSuperAdmin = UserModule.role === RoleEnum.ROLE_SUPER_ADMIN;
        const filter = new FilterFactory();

        filter.createSearchEntity('id');
        filter.setPlaceholder('id', 'По номеру заказа');
        filter.setName('id', 'search_id');
        filter.setPreviewName('id', 'Номер');
        filter.setIconClassName('id', 'icon-book');
        filter.setClassName('id', 'col-1-row-1');
        filter.setAction('id', 'orders/updateSearch');

        if (this.isRoleSuperAdmin) {
            filter.createSuggestionsEntity('sellerAuctionHouse');
            filter.setPlaceholder('sellerAuctionHouse', 'Начните вводить название АД');
            filter.setName('sellerAuctionHouse', 'suggestions_auction_house');
            filter.setPreviewName('sellerAuctionHouse', 'АД');
            filter.setAction('sellerAuctionHouse', 'orders/updateSuggestionsSelected');
            filter.setSearchAction('sellerAuctionHouse', 'orders/updateAuctionHouseSuggestionsValue');
            filter.setIconClassName('sellerAuctionHouse', 'icon-store');
            filter.setClassName('sellerAuctionHouse', 'col-2-row-1');

            filter.createSuggestionsEntity('sellerGallery');
            filter.setPlaceholder('sellerGallery', 'Начните вводить название галереи');
            filter.setName('sellerGallery', 'suggestions_gallery');
            filter.setPreviewName('sellerGallery', 'Галерея');
            filter.setAction('sellerGallery', 'orders/updateSuggestionsSelected');
            filter.setSearchAction('sellerGallery', 'orders/updateGallerySuggestionsValue');
            filter.setIconClassName('sellerGallery', 'icon-bank');
            filter.setClassName('sellerGallery', 'col-2-row-2');

            filter.createSuggestionsEntity('sellerPainter');
            filter.setPlaceholder('sellerPainter', 'Начните вводить имя автора');
            filter.setName('sellerPainter', 'suggestions_painter');
            filter.setPreviewName('sellerPainter', 'Автор');
            filter.setAction('sellerPainter', 'orders/updateSuggestionsSelected');
            filter.setSearchAction('sellerPainter', 'orders/updatePainterSuggestionsValue');
            filter.setIconClassName('sellerPainter', 'icon-pencil');
            filter.setClassName('sellerPainter', 'col-2-row-3');
        }

        filter.createDateIntervalPickerEntity('datetimeCreate');
        filter.setName('datetimeCreate', 'picker_date');
        filter.setTitle('datetimeCreate', 'Время оформ.');
        filter.setPreviewName('datetimeCreate', 'Вр. оформ.');
        filter.setDateIntervalList('datetimeCreate', {
            startDate: {
                key: 'datetimeCreatedStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'orders/updateStartDate',
            },
            endDate: {
                key: 'datetimeCreatedEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'orders/updateEndDate',
            },
        });
        filter.setClassName('datetimeCreate', 'col-1-row-2');

        filter.createSearchEntity('costOrderStart');
        filter.setPlaceholder('costOrderStart', 'Сумма от');
        filter.setName('costOrderStart', 'search_cost_order_start');
        filter.setPreviewName('costOrderStart', 'Сумма от');
        filter.setIconClassName('costOrderStart', 'icon-dollar');
        filter.setClassName('costOrderStart', this.isRoleSuperAdmin ? 'col-1-row-3' : 'col-2-row-1');
        filter.setAction('costOrderStart', 'orders/updateSearch');

        filter.createSearchEntity('costOrderEnd');
        filter.setPlaceholder('costOrderEnd', 'Сумма до');
        filter.setName('costOrderEnd', 'search_cost_order_start');
        filter.setPreviewName('costOrderEnd', 'Сумма до');
        filter.setIconClassName('costOrderEnd', 'icon-dollar');
        filter.setClassName('costOrderEnd', this.isRoleSuperAdmin ? 'col-1-row-4' : 'col-2-row-2');
        filter.setAction('costOrderEnd', 'orders/updateSearch');

        filter.createMultiSelectEntity('status');
        filter.setTitle('status', 'По статусу');
        filter.setName('status', 'multi_select_type');
        filter.setPreviewName('status', 'Статус');
        filter.setInitAction('status', 'orders/initStatuses');
        filter.setIconClassName('status', 'icon-status');
        filter.setClassName('status', this.isRoleSuperAdmin ? 'col-2-row-4' : 'col-1-row-3');
        filter.setAction('status', 'orders/updateMultiSelect');

        this.filterModel = filter.filterModel;
    }
}
