
import { Component, Vue } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import Loading from '@/components/Loading.vue';
import UserModule from '@/admin/store/user';

import CatalogsAuctionsModule from '@/admin/store/auctions/catalogsAuctions';
import { registerMap } from '@/lib/CatalogsAuctions';
import { getApiErrorMessage, getMapHasByValue } from '@/lib/utils/Utils';
import { registerForAuction, unregisterFromAuction } from '@/admin/api/operator';
import { showError, showMessage } from '@/lib/utils/message';

interface sortParamsInterface {
    action: string;
    field: string;
    sort: string;
}

interface tableParamsInterface {
    action: string;
    id: number;
    _: unknown;
}

@Component({
    components: {
        FlexLayout,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        Loading,
    },
})
export default class AuctionsListComponent extends Vue {
    get settings() {
        return CatalogsAuctionsModule.pageSettings;
    }

    get filterSettings() {
        return CatalogsAuctionsModule.filter.filterSettings;
    }

    get isLoading() {
        return CatalogsAuctionsModule.isLoading;
    }

    get paginationAction() {
        return CatalogsAuctionsModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: CatalogsAuctionsModule.updateItems,
            items: CatalogsAuctionsModule.pageSettings.pageAmountItems,
        };
    }

    get routeName(): string {
        return this.isOperator ? 'auction_bidding' : 'auction';
    }

    get isOperator(): boolean {
        return UserModule.isOperator;
    }

    tableAction(params: sortParamsInterface | tableParamsInterface): void {
        if (params.action === 'sort') {
            CatalogsAuctionsModule.sort({
                field: (params as sortParamsInterface).field,
                sort: (params as sortParamsInterface).sort,
            });

            return;
        }

        if (getMapHasByValue(registerMap, params.action)) {
            if (!('rows' in this.settings.table)) {
                return;
            }

            const match = this.settings.table.rows.find((row) => row.id === (params as tableParamsInterface).id);
            if (!match || !('youRegistered' in match)) {
                return;
            }

            match.youRegistered ? this.sendUnregister(match.id) : this.sendRegister(match.id);
        }
    }

    async sendRegister(id: number): Promise<void> {
        try {
            await registerForAuction(id);
            showMessage('Успешная регистрация');
            this.updateAuctionRegistration(id, true);
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    async sendUnregister(id: number): Promise<void> {
        try {
            await unregisterFromAuction(id);
            showMessage('Успешная отмена регистрации');
            this.updateAuctionRegistration(id, false);
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    updateAuctionRegistration(id: number, registered: boolean): void {
        CatalogsAuctionsModule.updateAuctionRegistration({ id, registered });
    }

    updateList() {
        CatalogsAuctionsModule.getList();
    }

    toAdd() {
        this.$router.push({ name: 'auction_create' });
    }

    async resetFilters() {
        await CatalogsAuctionsModule.clearSort();
        await CatalogsAuctionsModule.filter.resetFilter();
        CatalogsAuctionsModule.getList();
    }

    mounted(): void {
        CatalogsAuctionsModule.init();
    }

    beforeDestroy(): void {
        CatalogsAuctionsModule.reset();
    }
}
