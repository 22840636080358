
import { Component, Vue } from 'vue-property-decorator';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TableBlock from '@/components/table/Table.vue';
import Loading from '@/components/Loading.vue';

import AuctionHouseLotBidsEntity from '@/admin/store/auctions/lots/lotBidsAuctionHouseEntity';
import PainterLotBidsEntity from '@/admin/store/auctions/lots/lotBidsPainterEntity';
import AuctionLotEntity from '@/admin/store/auctions/lots/entity';

import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import WarningModal from '@/components/modules/Warning.vue';

@Component({
    components: {
        AppHeaderMain,
        WarningModal,
        TableBlock,
        TabsNav,
        Loading,
    },
})
export default class PainterLotCardModuleBets extends Vue {
    bidIdToDelete = 0;

    warningText = {
        yes: 'Удалить',
        description: `Вы уверены, что хотите удалить ставку?`,
    };

    get LotBidsEntity() {
        if (this.$route.params.sellerType === 'auction_house') {
            return AuctionHouseLotBidsEntity;
        }

        if (this.$route.params.sellerType === 'painter') {
            return PainterLotBidsEntity;
        }

        return AuctionHouseLotBidsEntity;
    }

    get tabsNav() {
        return AuctionLotEntity.tabsNav;
    }

    get currentTab() {
        return AuctionLotEntity.tabsNav.bids.id;
    }

    get isLoading() {
        return this.LotBidsEntity.isLoading;
    }

    get settings() {
        return this.LotBidsEntity.pageSettings;
    }

    get filterSettings() {
        return this.LotBidsEntity.filter.filterSettings;
    }

    updateList() {
        this.LotBidsEntity.getList();
    }

    async resetFilters() {
        await this.LotBidsEntity.clearSort();
        this.LotBidsEntity.getList();
    }

    async tableAction(params: any) {
        if (params.action === 'sort') {
            this.LotBidsEntity.sort({ field: params.field, sort: params.sort });
        }

        if (params.action === 'delete') {
            this.bidIdToDelete = params.id;
        }
    }

    async cancelBid(value: number) {
        await this.LotBidsEntity.cancelBid(value);
        this.LotBidsEntity.getList();

        this.bidIdToDelete = 0;
    }

    closeModal() {
        this.bidIdToDelete = 0;
    }

    mounted() {
        if (!AuctionLotEntity.titleReturnData.edit.title.length) {
            AuctionLotEntity.initTitleReturnById(this.$route.params.lotId);
        }

        this.LotBidsEntity.init(this.$route.params.lotId);
    }

    beforeDestroy() {
        this.LotBidsEntity.reset();
    }
}
