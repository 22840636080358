
import { Component, Vue } from 'vue-property-decorator';

import ExchangeRates from '@/components/templates/ExchangeRates.vue';
import SuggestionsTree from '@/components/suggestions/Tree.vue';

import LotEntityModule from '@/admin/store/auctions/lots/entity';
import { InputSuggestionMultipleItem } from '@/lib/formFactory/inputSuggestionMultiple.interface';
import { LotModelInterface } from '@/interfaces/models/lot.interface';

@Component({
    components: {
        ExchangeRates,
        SuggestionsTree,
    },
})
export default class LotCurrenciesComponent extends Vue {
    get model(): LotModelInterface {
        return LotEntityModule.model;
    }

    get module(): typeof LotEntityModule {
        return LotEntityModule;
    }

    selectAllowedCurrency(params: InputSuggestionMultipleItem): void {
        LotEntityModule.updateAllowedCurrencies(params);
    }
}
