import { OrdersModelInterface } from '@/interfaces/models/orders.interface';
import InputEntity from '@/lib/formFactory/inputEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';

export default class EntityModel {
    model: OrdersModelInterface;

    constructor() {
        const statusEntity = new SelectEntity('status');
        statusEntity.setTitle('Статус');
        statusEntity.setRequired(true);

        const costDeliveryEntity = new InputEntity('costDelivery');

        const commentCancellationEntity = new InputEntity('commentCancellation');
        commentCancellationEntity.setTitle('Комментарий для отмены');

        this.model = {
            status: statusEntity.entity[statusEntity.entityName],
            costOrder: null,
            costDelivery: costDeliveryEntity.entity[costDeliveryEntity.entityName],
            commentCancellation: commentCancellationEntity.entity[commentCancellationEntity.entityName],
            commissionAcquiring: '',
            commissionAuctionHouse: '',
            commissionFinancialServices: '',
            commissionRightToFollow: '',
            commissionSite: '',
            createdAt: '',
            dateTimeLastStatusChange: '',
            userId: null,
            userName: '',
            sellerType: '',
            sellerId: null,
            sellerName: '',
            sellerParamName: '',
            costTotal: 0,
            costLots: 0,
            catalog: null,
        };
    }
}
