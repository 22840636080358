
import { Component, Watch, Ref, Vue } from 'vue-property-decorator';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import Loading from '@/components/Loading.vue';
import BiddingTable from './_biddingTable.vue';
import BiddingActions from './_biddingActions.vue';
import DescriptionModal from './_bidding-lot-description.vue';
import BiddingManual from './_biddingManual.vue';

import AuctionsEntityModule from '@/admin/store/auctions/catalogsAuctions/entity';
import { tabsNavInterface } from '@/interfaces/tabs';
import BtnDefault from '@/components/BtnDefault.vue';
import websockets, { WS } from '@/lib/utils/websockets';
import WebsocketsModule from '@/admin/store/websockets';
import { LotApiType, SocketBidParams } from '@/interfaces/catalogPublic.interface';
import { makeGetRequest } from '@/api/helpers';
import { API_URL } from '@/constants';
import { getLotsList } from '@/admin/api/lots';
import { generateFilter, getApiErrorMessage } from '@/lib/utils/Utils';
import { showError } from '@/lib/utils/message';
import SiteModule from '@/admin/store/site';
import UserModule from '@/admin/store/user';
import { getCatalogAuctionCard } from '@/admin/api/catalogAuction';
import { EventBus } from '@/lib/EventBus';
import { LotStatusEnum } from '@/admin/store/auctions/lots/entity';

function sortLotsByNumber(a: LotApiType, b: LotApiType): number {
    if (a.number < b.number) {
        return -1;
    }
    if (a.number > b.number) {
        return 1;
    }

    return 0;
}

export const LOT_EXCLUDE_STATUSES = [LotStatusEnum.STATUS_SOLD, LotStatusEnum.STATUS_NOT_SOLD, LotStatusEnum.DRAFT];

interface BiddingActionParams {
    type: string;
    action: string;
    lotId: number;
}

export interface LotInterface {
    number: number;
    name: string;
    sizes: string;
    material: string;
    year: string;
    description: string;
    categories: string;
    estimate: unknown;
    author: unknown;
    photo?: string;
    photos?: string[];
    status: string;
    published: boolean;
}

enum CatalogStatusEnum {
    RUN = 'run',
    COMPLETED = 'completed',
}

@Component({
    components: {
        TabsNav,
        TitleReturn,
        Loading,
        BtnDefault,
        BiddingTable,
        BiddingActions,
        DescriptionModal,
        BiddingManual,
    },
})
export default class AuctionBiddingComponent extends Vue {
    @Ref('$bidTable') bidTable!: BiddingTable;

    isLoading = false;
    isLotsLoading = false;
    lot: LotInterface | null = null;
    lotId: number | null = null;
    lots: LotApiType[] = [];
    isRunning = false;
    isFinished = false;
    isShowDescription = false;
    strings: Record<string, string> = {
        title: 'Торги',
        description: 'Описание',
        noLotsMessage: 'В данном аукционе нет лотов',
        biddingFinishedMessage: 'Торги завершены',
    };
    activeIndex = 0;
    bidAutoApprove = false;

    wsLoaded = false;

    userSubscribers: Record<string, any> = {
        bidAutoApprovedChanged: function (data: { bidAutoApprove: boolean }) {
            EventBus.$emit('update-bid-auto-approve', data.bidAutoApprove);
        },
    };

    get tabsNav(): tabsNavInterface {
        return AuctionsEntityModule.tabsNav;
    }

    get currentTab(): string {
        return AuctionsEntityModule.allTabs.bidding.id;
    }

    get websocket(): WS | null {
        return WebsocketsModule.ws;
    }

    get catalogId(): number {
        return +this.$route.params.catalogId;
    }

    get photos(): string[] {
        return this.lot?.photos?.length ? this.lot.photos : [this.lot?.photo || ''];
    }

    get isShowNoLotsMessage(): boolean {
        return !this.lots.length && !this.isLotsLoading && !this.isFinished;
    }

    showDescription(): void {
        this.isShowDescription = true;
    }

    hideDescription(): void {
        this.isShowDescription = false;
    }

    handleBiddingAction(params: BiddingActionParams): void {
        SiteModule.SET_IS_BLOCK(true);
        this.wsSend(params);
    }

    wsSend(data: BiddingActionParams): void {
        this.websocket?.ws.send(JSON.stringify(data));
    }

    handleLotSwitch(params: { action: string; data: string | number }): void {
        if (params.action === 'to') {
            this.updateLotById(params.data as number);

            return;
        }
    }

    initTabs(): void {
        if (Object.values(this.tabsNav).length === 0) {
            AuctionsEntityModule.initTabsItems();
        }
    }

    updateLot(lot: LotApiType): void {
        this.lotId = lot.id;
        this.lot = {
            number: lot.number,
            name: lot.name,
            sizes: lot.sizes,
            material: lot.material,
            year: lot.year,
            description: lot.description,
            categories: lot.categories?.length ? lot.categories?.map((cat) => cat.name).join(', ') : '',
            estimate: lot.priceEstimate || '',
            author: lot.author,
            photo: lot.photo,
            photos: lot.photos,
            status: lot.status,
            published: lot.isPublished,
        };
    }

    async updateLotById(id: number): Promise<void> {
        try {
            this.isLoading = true;
            const result = await makeGetRequest(`${API_URL}/api/public/lot/${id}`);
            this.updateLot(result.data.result.item);
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            showError(getApiErrorMessage(error));
            throw new Error(error as string);
        }
    }

    updateCurrentLot(): void {
        if (this.lotId === null) {
            return;
        }

        this.updateLotById(this.lotId);
    }

    setWs(): void {
        if (!this.wsLoaded && this.websocket) {
            this.initAuctionSubscriber();
            this.initUserSubscriber();

            this.wsLoaded = true;
        }
    }

    initAuctionSubscriber(): void {
        this.websocket?.auctionSubscribe(+this.$route.params.catalogId, {
            newLot: async () => {
                this.updateCurrentLot();
            },
            endLot: async () => {
                this.updateCurrentLot();
                this.updateLots();
            },
            cancelLot: () => {
                this.updateCurrentLot();
                this.updateLots();
            },
            newBid: (data: SocketBidParams) => {
                this.bidTable?.updateBid(data);
            },
            started: () => {
                this.isRunning = true;
                this.isFinished = false;
            },
            finished: async () => {
                this.isRunning = false;
                this.isFinished = true;
            },
        });
    }

    initUserSubscriber(): void {
        if (!UserModule.id) {
            return;
        }
        this.websocket?.userSubscribe(UserModule.id, this.userSubscribers);
    }

    async updateLots(): Promise<void> {
        const table = await getLotsList(
            1,
            '',
            generateFilter({ id: 'auction', value: this.$route.params.catalogId }),
            999,
        );

        this.lots = (table.rows as LotApiType[])
            .filter((row) => !LOT_EXCLUDE_STATUSES.includes(row.status as LotStatusEnum))
            .sort(sortLotsByNumber);
    }

    setInitialLot(): void {
        const currentLot = this.lots.find((lot: LotApiType) => lot.status === 'online_auction');
        const lot = currentLot || this.lots[0];

        if (lot) {
            this.updateLotById(lot.id);
        }
    }

    async init(): Promise<void> {
        this.isLotsLoading = true;
        const promises: [Promise<any>, Promise<void>, Promise<WS>] = [
            getCatalogAuctionCard(+this.$route.params.catalogId),
            this.updateLots(),
            websockets(),
        ];

        const result = await Promise.all(promises);
        if (result[0]?.status === CatalogStatusEnum.RUN) {
            this.isRunning = true;
            this.isFinished = false;
        }

        if (result[0]?.status === CatalogStatusEnum.COMPLETED) {
            this.isRunning = false;
            this.isFinished = true;
        }

        this.bidAutoApprove = result[0]?.bidAutoApprove ?? this.bidAutoApprove;
        this.isLotsLoading = false;

        this.setInitialLot();

        this.setWs();
    }

    async created(): Promise<void> {
        this.initTabs();
        this.init();

        EventBus.$on('update-user-subscriber', (data: any) => {
            this.userSubscribers = { ...this.userSubscribers, ...data };
            this.initUserSubscriber();
        });

        EventBus.$on('update-bid-auto-approve', (data: boolean) => {
            this.bidAutoApprove = data;
        });
    }

    @Watch('websocket')
    onWsChange(val: WS): void {
        if (val) {
            this.setWs();
        } else {
            this.wsLoaded = false;
        }
    }
}
