/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class lotBidsAuctionHouseFilterEntity {
    filterHandlers = {
        update: 'LotBidsAuctionHouseFilter/updateFilter',
        reset: 'LotBidsAuctionHouseFilter/resetFilter',
        resetState: 'LotBidsAuctionHouseFilter/resetFilterState',
        removeSelected: 'LotBidsAuctionHouseFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSearchEntity('userEmail');
        filter.setName('userEmail', 'search_user_email');
        filter.setPlaceholder('userEmail', 'Email');
        filter.setIconClassName('userEmail', 'icon-email');
        filter.setAction('userEmail', 'lotBidsAuctionHouse/updateSearch');
        filter.setClassName('userEmail', 'row-1');
        filter.setPreviewName('userEmail', 'Email');

        filter.createSearchEntity('bidStart');
        filter.setName('bidStart', 'search_bid_start');
        filter.setPlaceholder('bidStart', 'Сумма от');
        filter.setIconClassName('bidStart', 'icon-dollar');
        filter.setAction('bidStart', 'lotBidsAuctionHouse/updateSearch');
        filter.setClassName('bidStart', 'row-2');
        filter.setPreviewName('userEmail', 'Сумма от');

        filter.createSearchEntity('bidEnd');
        filter.setName('bidEnd', 'search_bid_end');
        filter.setPlaceholder('bidEnd', 'Сумма до');
        filter.setIconClassName('bidEnd', 'icon-dollar');
        filter.setAction('bidEnd', 'lotBidsAuctionHouse/updateSearch');
        filter.setClassName('bidEnd', 'row-3');
        filter.setPreviewName('userEmail', 'Сумма до');

        filter.createDateIntervalPickerEntity('dateTimeCreatedAt');
        filter.setName('dateTimeCreatedAt', 'picker_date');
        filter.setTitle('dateTimeCreatedAt', 'Время разм.');
        filter.setPreviewName('dateTimeCreatedAt', 'Вр. разм.');
        filter.setDateIntervalList('dateTimeCreatedAt', {
            startDate: {
                key: 'dateTimeCreatedAtStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'lotBidsAuctionHouse/updateStartDate',
            },
            endDate: {
                key: 'dateTimeCreatedAtEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'lotBidsAuctionHouse/updateEndDate',
            },
        });
        filter.setClassName('dateTimeCreatedAt', 'row-4');

        this.filterModel = filter.filterModel;
    }
}
