
import { Vue, Component, Prop } from 'vue-property-decorator';
import TableBlock from '@/components/table/Table.vue';
import Loading from '@/components/Loading.vue';
import OrderEntity from '@/admin/store/auctions/orders/entity';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { getLotsList, getLotsStatuses, LotsStatusesApiType } from '@/admin/api/lots';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { generateSort } from '@/lib/utils/Utils';
import { prepareOrderLotsTable } from '@/lib/Orders';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

@Component({
    components: {
        TableBlock,
        Loading,
    },
})
export default class LotAddTableComponent extends Vue {
    @Prop({ required: true }) readonly sellerType!: string;
    @Prop({ required: true }) readonly sellerId!: number;
    @Prop({ required: false }) readonly catalog!: { id: number; value: string } | null;

    isLoading = true;
    settings: PageInterface | null = null;
    sort = generateSort({ id: 'name', value: 'ASC' });
    statuses: { id: string; name: string }[] = [];

    closeModal(): void {
        this.$emit('close', false);
    }

    async getTable(): Promise<void> {
        this.isLoading = true;
        let filter = '';

        if (this.sellerType === 'auction_house') {
            filter += `&filters[0][id]=sellerAuctionHouse&filters[0][value]=${this.sellerId}`;
            filter += `&filters[1][id]=status&filters[1][value]=not_sold`;
        }

        if (this.catalog?.id) {
            filter += `&filters[2][id]=auction&filters[2][value]=${this.catalog.id}`;
        }

        if (this.sellerType === 'gallery') {
            filter += `&filters[0][id]=sellerGallery&filters[0][value]=${this.sellerId}`;
            filter += `&filters[1][id]=status&filters[1][value]=gallery_placed`;
        }

        if (this.sellerType === 'painter') {
            filter += `&filters[0][id]=sellerPainter&filters[0][value]=${this.sellerId}`;
            filter += `&filters[1][id]=status&filters[1][value]=painter_placed`;
        }

        const promises: Promise<TableApiInterface | LotsStatusesApiType>[] = [
            getLotsList((this.settings as PageInterface).pageCurrent, this.sort, filter, 15),
        ];
        if (!this.statuses.length) {
            promises.push(getLotsStatuses());
        }

        const [table, statuses] = await Promise.all(promises);
        if (statuses) {
            this.statuses = statuses as LotsStatusesApiType;
        }

        (this.settings as PageInterface).table = await prepareOrderLotsTable(table as TableApiInterface, this.statuses);

        this.isLoading = false;
    }

    paginationAction(page: number): void {
        (this.settings as PageInterface).pageCurrent = page;
        this.getTable();
    }

    tableAction(params: { action: string; id: number; _: undefined }): void {
        if (params.action === 'add') {
            this.addLot(params.id);
        }
    }

    async addLot(lotId: number): Promise<void> {
        OrderEntity.addLot(lotId);
    }

    created(): void {
        const page = new PageEntity();
        page.setTitle('');
        this.settings = page.values;

        this.getTable();
    }
}
