
import { Component, Vue } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import TableBlock from '@/components/table/TableLots.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import Loading from '@/components/Loading.vue';

import OrdersModule from '@/admin/store/auctions/orders';
import UserModule from '@/admin/store/user';
import RoleModule, { RoleEnum } from '@/admin/store/role';

@Component({
    components: {
        FlexLayout,
        TableBlock,
        SelectCustom,
        AppHeaderMain,
        Loading,
    },
})
export default class LotsMain extends Vue {
    get isWriteAllow(): boolean {
        return RoleModule.accessByRouterName[this.$route.name as RoleEnum]?.write.includes(UserModule.role as RoleEnum);
    }

    get isLoading(): boolean {
        return OrdersModule.isLoading;
    }

    get settings() {
        return OrdersModule.pageSettings;
    }

    get filterSettings() {
        return OrdersModule.filter.filterSettings;
    }

    get sellerType() {
        return OrdersModule.sellerType;
    }

    get paginationAction() {
        return OrdersModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: OrdersModule.updateItems,
            items: OrdersModule.pageSettings.pageAmountItems,
        };
    }

    updateSellerType(params: { id: string; value: string }) {
        OrdersModule.updateSellerType(params);
    }

    updateList() {
        OrdersModule.getList();
    }

    async resetFilters() {
        await OrdersModule.clearSort();
        OrdersModule.getList();
    }

    mounted() {
        OrdersModule.init();
    }

    beforeDestroy() {
        OrdersModule.reset();
    }
}
