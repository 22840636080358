import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getCatalogAuctionList, getCatalogAuctionStatuses } from '@/admin/api/catalogAuction';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { prepareTable, registerMap } from '@/lib/CatalogsAuctions';
import filterModel from './filter';
import CatalogsAuctionsFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import {
    FilterCheckboxParamsInterface,
    FilterInputSuggestionsParamsInterface,
    FilterSearchParamsInterface,
    FilterSelectParamsInterface,
} from '@/lib/layouts/page/filterParams.interface';
import { processListForSelect } from '@/lib/utils/form';
import { getAuctionHouseList } from '@/admin/api/auctionHouse';
import { getCitiesList } from '@/admin/api/city';
import { generateFilter } from '@/lib/utils/Utils';

export const MODULE_NAME = 'catalogsAuctions';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CatalogsAuctionsModule extends PageBaseModule {
    filter: Filter;

    constructor(module: CatalogsAuctionsModule) {
        super(module);
        const page = new PageEntity();

        page.setTitle('Каталоги');
        page.setDefaultSortField('name');
        page.setSortName('catalogsAuctionsSort');
        this.pageSettings = page.values;

        const filter = new filterModel();
        this.filter = getModule(CatalogsAuctionsFilter);
        this.filter.setTemplateClassName('template-lg');
        this.filter.setBtnClassName('row-5');
        this.filter.setFilterName('catalogsAuctionsFilter');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Action({ rawError: true })
    async initStatuses() {
        const statuses = await getCatalogAuctionStatuses();

        return processListForSelect(statuses);
    }

    @Action({ rawError: true })
    async init() {
        this.SET_IS_LOADING(true);
        this.setDefaultSort();

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);

        const sort = await this.getSortForRequest();
        const result = await getCatalogAuctionList(
            this.pageSettings.pageCurrent,
            sort,
            this.filter.filterSettings.filter,
            this.pageSettings.pageAmountItems,
        );

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.getProcessedTable(table);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    async getProcessedTable(table: TableApiInterface) {
        return await prepareTable(table);
    }

    @Action({ rawError: true })
    updateAuctionRegistration(params: { id: number; registered: boolean }) {
        const rows = (this.pageSettings.table as TableInterface).rows.map((row) => {
            if (row.id !== params.id) {
                return row;
            }

            return {
                ...row,
                youRegistered: params.registered,
                _actions: [registerMap.get(params.registered)],
            };
        });

        this.SET_TABLE({ ...this.pageSettings.table, rows } as TableInterface);
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSelect(params: FilterSelectParamsInterface) {
        await this.filter.updateSelect(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSuggestionsCatalogSearchFilter(params: { key: string; value: string }) {
        const filterItems = [];

        if (params.value) {
            filterItems.push({ id: 'name', value: params.value });
        }
        this.filter.updateSuggestionsValue(params);

        const table = await getCatalogAuctionList(
            1,
            this.pageSettings.filterRequestSort ?? '',
            generateFilter(filterItems),
            50,
        );
        const list = table ? Object.values(table.rows) : [];

        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(list) });
    }

    @Action({ rawError: true })
    async updateSuggestionsAuctionHouseSearchFilter(params: { key: string; value: string }) {
        const filter = params.value ? `&filters[0][id]=name&filters[0][value]=${params.value}` : '';
        this.filter.updateSuggestionsValue(params);

        const table = await getAuctionHouseList(1, this.pageSettings.filterRequestSort ?? '', filter, 50);
        const list = table ? Object.values(table.rows) : [];

        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(list) });
    }

    @Action({ rawError: true })
    async updateSuggestionsCitySearchFilter(params: { key: string; value: string }) {
        const filter = params.value ? `&filters[0][id]=name&filters[0][value]=${params.value}` : '';
        this.filter.updateSuggestionsValue(params);

        const table = await getCitiesList(1, this.pageSettings.filterRequestSort ?? '', filter, 50);
        const list = table ? Object.values(table.rows) : [];

        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(list) });
    }

    @Action({ rawError: true })
    updateSuggestionsSelectedFilter(params: FilterInputSuggestionsParamsInterface) {
        params = { ...params, value: { id: params.value.value, value: params.value.value } };
        this.filter.updateSuggestionsSelected(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateCheckboxFilter(params: FilterCheckboxParamsInterface): Promise<void> {
        await this.filter.updateCheckbox(params);
        this.filter.updateFilter();
    }
}

export default getModule(CatalogsAuctionsModule);
