import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/admin/store';
import BidsBaseModule from '@/admin/store/bids';
import { PageSort } from '@/lib/layouts/page/page.interface';
import filterEntity from './lotBidsPainterFilter';
import filterModel from './lotBidsPainterFilterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { FilterDateParamsInterface, FilterSearchParamsInterface } from '@/lib/layouts/page/filterParams.interface';
import { getBidsList } from '@/admin/api/bids';
import LotEntity from './entity';
import { getLotCard } from '@/admin/api/lots';

const MODULE_NAME = 'lotBidsPainter';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class LotBidsPainterModule extends BidsBaseModule {
    filter: Filter;
    allowBidCancelStatus = 'painter_placed';

    constructor(module: LotBidsPainterModule) {
        super(module);

        const filter = new filterModel();
        this.filter = getModule(filterEntity);
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterName('lotBidsPainter');
        this.filter.setFilterHandlers(filter.filterHandlers);
        this.filter.setFilterModel(filter.filterModel);
    }

    @Mutation
    SET_SORT(sorts: PageSort) {
        this.pageSettings.sort = sorts;
        window.localStorage.lotBidsPainterSort = JSON.stringify(sorts);
    }

    @Action({ rawError: true })
    async init(lotId: string) {
        this.context.commit('SET_ID', lotId);
        this.context.commit(
            'SET_SORT',
            window.localStorage.lotBidsPainterSort ? JSON.parse(window.localStorage.lotBidsPainterSort) : {},
        );

        await this.filter.init();

        this.context.dispatch('getList');
    }

    @Action({ rawError: true })
    async getList() {
        this.context.commit('SET_IS_LOADING', true);

        let allowBidCancel = false;
        const sort = await this.context.dispatch('getSortForRequest');
        const filter = `&filters[0][id]=lot&filters[0][value]=${this.id}${this.filter.filterSettings.filter}`;

        const promises = [getBidsList(this.pageSettings.pageCurrent, `&${sort}`, filter)];

        if (String(LotEntity.cardId) === this.id) {
            allowBidCancel = LotEntity.model.status === this.allowBidCancelStatus;
        } else {
            promises.push(getLotCard(this.id));
        }

        const [result, lot] = await Promise.all(promises);
        allowBidCancel = lot ? lot.status === this.allowBidCancelStatus : allowBidCancel;

        this.context.dispatch('setList', await this.prepareTable({ table: result, allowBidCancel }));
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateStartDate(params: FilterDateParamsInterface) {
        await this.filter.updateStartDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateEndDate(params: FilterDateParamsInterface) {
        await this.filter.updateEndDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    clearSort() {
        this.context.commit('SET_SORT', {});
    }
}
export default getModule(LotBidsPainterModule);
