import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { getLotsList, getLotsStatuses } from '@/admin/api/lots';
import { Select } from '@/lib/formFactory/select.interface';
import { getPainterList } from '@/admin/api/painter';
import { getGalleryList } from '@/admin/api/gallery';
import { getAuctionHouseList } from '@/admin/api/auctionHouse';
import { processListForSelect } from '@/lib/utils/form';
import filterModel from './filter';
import { Filter } from '@/lib/layouts/page/filter.interface';
import AuctionLotsFilter from './filterEntity';
import {
    FilterDateParamsInterface,
    FilterInputSuggestionsParamsInterface,
    FilterSearchParamsInterface,
    FilterSelectParamsInterface,
} from '@/lib/layouts/page/filterParams.interface';
import { prepareTable } from '@/admin/lib/Lots';
import { generateSort } from '@/lib/utils/Utils';
import UserModule from '@/admin/store/user';
import RoleModule, { RoleEnum } from '@/admin/store/role';

export const MODULE_NAME = 'lots';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class LotsModule extends PageBaseModule {
    filter: Filter;
    sellerType: Select;
    statuses: { id: string; name: string }[] = [];

    constructor(module: LotsModule) {
        super(module);
        const page = new PageEntity();

        page.setTitle('Лоты');
        page.setSortName('lotsSort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;

        const filter = new filterModel();
        this.filter = getModule(AuctionLotsFilter);
        this.filter.setTemplateClassName(`template-${UserModule.role === RoleEnum.ROLE_SUPER_ADMIN ? 'lg' : 'sm'}`);
        this.filter.setFilterName('lotsFilter');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);

        this.sellerType = {
            key: 'sellerType',
            type: 'select',
            title: 'Тип продавца',
            list: [
                { id: 'all', value: 'Все' },
                { id: 'auction_house', value: 'Аукционные дома' },
                { id: 'painter', value: 'Авторы' },
                { id: 'gallery', value: 'Галереи' },
            ],
            current: { id: 'all', value: 'Все' },
            required: false,
            disabled: false,
            error: {
                class: '',
                message: '',
            },
        };
    }

    @Mutation
    SET_SELLER_TYPE(selected: { id: string; value: string } | null) {
        if (selected === null) {
            return;
        }

        this.sellerType.current = selected;
        window.localStorage.lotsSellerType = JSON.stringify(selected);
    }

    @Mutation
    SET_SELLER_TYPE_ERROR() {
        this.sellerType.error.class = 'cp-error';
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Mutation
    RESET_SELLER_TYPE_ERROR() {
        this.sellerType.error.class = '';
    }

    @Mutation
    SET_STATUSES(list: { id: string; name: string }[]) {
        this.statuses = list;
    }

    @Mutation
    UPDATE_ROW_CHECKED(id: number) {
        const table = this.pageSettings.table as TableInterface;
        table.rows = table.rows.map((row) => {
            if (row.id === id) {
                row.checked = !row.checked;
            }

            return row;
        });

        table.titles.forEach((current, index) => {
            if (current.name === 'check' && current.value === true) {
                table.titles[index].value = false;
            }
        });

        this.pageSettings.table = table;
    }

    @Action({ rawError: true })
    toggleCheckAll() {
        const table = this.pageSettings.table as TableInterface;
        let value = false;

        const titles = table.titles.map((title) => {
            if (title.name === 'check') {
                title.value = !title.value;
                value = title.value;
            }

            return title;
        });

        const rows = table.rows.map((row) => {
            row.checked = value;

            return row;
        });

        table.titles = titles;
        table.rows = rows;

        this.SET_TABLE(table);
    }

    @Action({ rawError: true })
    initSellerTypeSelect() {
        const role = UserModule.role?.toLowerCase();
        this.sellerType.list.forEach((type) => {
            if (type.id === 'all') {
                return;
            }

            if (role?.indexOf(type.id as string) !== -1) {
                this.SET_SELLER_TYPE({ ...type, id: type.id as string });

                return;
            }

            if (UserModule.role === RoleEnum.ROLE_SUPER_ADMIN) {
                this.SET_SELLER_TYPE(
                    window.localStorage.lotsSellerType ? JSON.parse(window.localStorage.lotsSellerType) : null,
                );
            }
        });
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();
        this.initSellerTypeSelect();

        this.context.commit('SET_STATUSES', await getLotsStatuses());
        this.SET_FILTER_REQUEST_SORT(await generateSort({ id: 'name', value: 'ASC' }));

        if (UserModule.role !== 'ROLE_SELLER_PAINTER') {
            localStorage.removeItem(`${this.filter.filterSettings.name}SellerAuctionHouse`);
            localStorage.removeItem(`${this.filter.filterSettings.name}SellerGallery`);
        }

        if (UserModule.role !== 'ROLE_SELLER_GALLERY') {
            localStorage.removeItem(`${this.filter.filterSettings.name}SellerPainter`);
            localStorage.removeItem(`${this.filter.filterSettings.name}SellerAuctionHouse`);
        }

        if (UserModule.role === 'ROLE_SELLER_AUCTION_HOUSE_ADMIN') {
            localStorage.removeItem(`${this.filter.filterSettings.name}SellerGallery`);
            localStorage.removeItem(`${this.filter.filterSettings.name}SellerPainter`);
        }

        this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();

        let filter =
            this.sellerType.current.id !== 'all' &&
            RoleModule.accessByRouterName['lots']?.write.includes(UserModule.role as RoleEnum)
                ? `filters[0][id]=sellerType&filters[0][value]=${this.sellerType.current.id}`
                : '';
        if (!sort && filter.length > 0) {
            filter = `&${filter}`;
        }

        filter += this.filter.filterSettings.filter;

        const result = await getLotsList(
            this.pageSettings.pageCurrent,
            sort,
            filter,
            this.pageSettings.pageAmountItems,
        );

        this.setList(result);
        this.SET_IS_LOADING(false);
    }

    // TODO: get rid of lists for table processing
    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await prepareTable(table, this.statuses);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    async updateSellerType(selected: { id: string; value: string }) {
        this.context.commit('RESET_SELLER_TYPE_ERROR');
        await this.context.commit('SET_SELLER_TYPE', selected);
        this.getList();
    }

    @Action({ rawError: true })
    setSellerTypeError() {
        this.context.commit('SET_SELLER_TYPE_ERROR');
    }

    @Action({ rawError: true })
    resetSellerTypeError() {
        this.context.commit('RESET_SELLER_TYPE_ERROR');
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSelect(params: FilterSelectParamsInterface) {
        await this.filter.updateSelect(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateStartDate(params: FilterDateParamsInterface) {
        await this.filter.updateStartDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateEndDate(params: FilterDateParamsInterface) {
        await this.filter.updateEndDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSuggestionsSelected(params: FilterInputSuggestionsParamsInterface) {
        await this.filter.updateSuggestionsSelected(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateAuctionSuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        const table = await getAuctionHouseList(
            1,
            this.pageSettings.filterRequestSort ?? '',
            `&filters[0][id]=name&filters[0][value]=${params.value}`,
            50,
        );
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }

    @Action({ rawError: true })
    async updateGallerySuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        const table = await getGalleryList(
            1,
            this.pageSettings.filterRequestSort ?? '',
            `&filters[0][id]=name&filters[0][value]=${params.value}`,
            50,
        );
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }

    @Action({ rawError: true })
    async updatePainterSuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        const table = await getPainterList(
            1,
            this.pageSettings.filterRequestSort ?? '',
            `&filters[0][id]=name&filters[0][value]=${params.value}`,
            50,
        );
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }
}

export default getModule(LotsModule);
