
import { Component, Vue, Watch } from 'vue-property-decorator';
import AppHeader from './_appHeader.vue';
import Auctions from './_auctions.vue';
import AuctionCard from './card/Main.vue';
import AuctionLots from './card/_lots.vue';
import AuctionBidding from './card/_bidding.vue';
import Lots from './_lots.vue';
import LotCard from './lotCard/Main.vue';
import Orders from './_orders.vue';
import OrderCard from './orderCard/Main.vue';

import AuctionsModule from '@/admin/store/auctions';

@Component({
    components: {
        AppHeader,
        Auctions,
        AuctionCard,
        AuctionLots,
        AuctionBidding,
        Lots,
        LotCard,
        Orders,
        OrderCard,
    },
})
export default class AuctionsMain extends Vue {
    currentTab = '';
    title = '';
    icon = '';

    @Watch('$route')
    onRouteChange() {
        this.updateHeaderData();
    }

    get tabsNav() {
        return AuctionsModule.tabsNav;
    }

    updateHeaderData() {
        if (!this.tabsNav) {
            return;
        }

        if (typeof this.$route.name !== 'string') {
            return;
        }

        const auctionTabRoutes = ['auctions', 'auction_create', 'auction', 'auction_lots', 'auction_bidding'];
        const lotTabRoutes = ['lots', 'lot', 'lot_bids'];
        const orderTabRoutes = ['orders', 'order'];

        if (auctionTabRoutes.includes(this.$route.name)) {
            this.currentTab = AuctionsModule.allTabs.auctions.id;
            this.title = 'Каталоги';
            this.icon = 'book';
        }

        if (lotTabRoutes.includes(this.$route.name)) {
            this.currentTab = AuctionsModule.allTabs.lots.id;
            this.title = 'Лоты';
            this.icon = 'pencil';
        }

        if (orderTabRoutes.includes(this.$route.name)) {
            this.currentTab = AuctionsModule.allTabs.orders.id;
            this.title = 'Заказы';
            this.icon = 'bank';
        }
    }

    async mounted() {
        await AuctionsModule.initTabsItems();
        this.updateHeaderData();
    }
}
