import { Action, Mutation } from 'vuex-module-decorators';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { cancelBid } from '../api/bids';
import PageBaseModule from './page';
import { getApiErrorMessage, showError } from '@/lib/utils/Utils';

export default class BidsBaseModule extends PageBaseModule {
    id = '';

    constructor(module: BidsBaseModule) {
        super(module);
    }

    @Mutation
    SET_ID(id: string) {
        this.id = id;
    }

    @Action({ rawError: true })
    setList(table: TableApiInterface) {
        this.context.commit('SET_TABLE', table);
        this.context.commit('SET_IS_LOADING', false);
    }

    @Action({ rawError: true })
    prepareTable(params: { table: TableApiInterface; allowBidCancel?: boolean }) {
        const hiddenColumns = Object.values(params.table.titles)
            .filter((title) => title.visible === false || title.id === 'userId')
            .map((title) => title.id);

        const rows: any = [];
        let index: string;
        let row: any;
        for ([index, row] of Object.entries(params.table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'user') {
                    cell = `<a href="${window.location.origin}/users/user/${row.userId}/" class="cp-link" target="_blank">
                        <span class="cp-table-column__bold">${row.user}</span>
                    </a>`;
                }

                if (i === '_actions' && params.allowBidCancel && row['isLast'] === true) {
                    cell = ['delete'];
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        const titles = [...params.table.titles, { id: '_actions', name: '', visible: true }];

        return {
            titles,
            rows: rows,
            sortFields: params.table.sortFields,
            pagination: params.table.pagination ? params.table.pagination : {},
            totalItems: params.table.totalItems,
            filters: params.table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    async cancelBid(value: number) {
        try {
            await cancelBid(value);
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_IS_LOADING', true);
        this.context.commit('SET_TABLE', {});
    }
}
