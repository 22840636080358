import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import { getOrdersList, getOrdersStatuses } from '@/admin/api/orders';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { Select } from '@/lib/formFactory/select.interface';
import { PageSort } from '@/lib/layouts/page/page.interface';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from './filter';
import OrdersFilter from './filterEntity';
import { processListForSelect } from '@/lib/utils/form';
import { Filter } from '@/lib/layouts/page/filter.interface';
import {
    FilterDateParamsInterface,
    FilterInputSuggestionsParamsInterface,
    FilterMultiSelectParamsInterface,
    FilterSearchParamsInterface,
    FilterSelectParamsInterface,
} from '@/lib/layouts/page/filterParams.interface';
import { getPainterList } from '@/admin/api/painter';
import { getGalleryList } from '@/admin/api/gallery';
import { getAuctionHouseList } from '@/admin/api/auctionHouse';
import { prepareTable } from '@/lib/Orders';
import { generateSort } from '@/lib/utils/Utils';
import UserModule from '@/admin/store/user';

const MODULE_NAME = 'orders';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class OrdersModule extends PageBaseModule {
    filter: Filter;
    sellerType: Select;
    painters: { id: number; name: string }[] = [];
    galleries: { id: number; name: string }[] = [];
    auctionHouses: { id: number; name: string }[] = [];
    statuses: { id: string; name: string }[] = [];

    constructor(module: OrdersModule) {
        super(module);

        const page = new PageEntity();
        page.setTitle('Заказы');

        this.pageSettings = page.values;

        const filter = new filterModel();
        this.filter = getModule(OrdersFilter);
        this.filter.setTemplateClassName('template-lg');
        this.filter.setFilterName('OrdersFilter');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);

        this.sellerType = {
            key: 'sellerType',
            type: 'select',
            title: 'Тип продавца',
            list: [
                { id: 'all', value: 'Все' },
                { id: 'auction_house', value: 'Аукционные дома' },
                { id: 'painter', value: 'Авторы' },
                { id: 'gallery', value: 'Галереи' },
            ],
            current: { id: 'all', value: 'Все' },
            required: false,
            disabled: false,
            error: {
                class: '',
                message: '',
            },
        };
    }

    @Mutation
    SET_SORT(sorts: PageSort) {
        this.pageSettings.sort = sorts;
        window.localStorage.ordersSort = JSON.stringify(sorts);
    }

    @Mutation
    SET_SELLER_TYPE(selected: { id: string; value: string } | null) {
        if (selected === null) {
            return;
        }

        this.sellerType.current = selected;
        window.localStorage.ordersSellerType = JSON.stringify(selected);
    }

    @Mutation
    RESET_SELLER_TYPE_ERROR() {
        this.sellerType.error.class = '';
    }

    @Mutation
    SET_STATUSES(list: { id: string; name: string }[]) {
        this.statuses = list;
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Action({ rawError: true })
    async init() {
        this.context.commit('SET_SORT', window.localStorage.lotsSort ? JSON.parse(window.localStorage.lotsSort) : {});
        this.SET_FILTER_REQUEST_SORT(await generateSort({ id: 'name', value: 'ASC' }));

        this.context.commit('SET_STATUSES', await getOrdersStatuses());

        if (UserModule.role !== 'ROLE_SUPER_ADMIN') {
            localStorage.removeItem('ordersSellerType');
        }

        this.context.commit(
            'SET_SELLER_TYPE',
            window.localStorage.ordersSellerType ? JSON.parse(window.localStorage.ordersSellerType) : null,
        );

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    initStatuses() {
        return this.statuses.map((status) => {
            return {
                checked: false,
                id: status.id,
                name: status.name,
                value: status.id,
            };
        });
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();

        let filter =
            this.sellerType.current.id !== 'all'
                ? `filters[0][id]=sellerType&filters[0][value]=${this.sellerType.current.id}`
                : '';
        if (!sort && filter.length > 0) {
            filter = `&${filter}`;
        }

        filter += this.filter.filterSettings.filter;

        const result = await getOrdersList(
            this.pageSettings.pageCurrent,
            sort,
            filter,
            this.pageSettings.pageAmountItems,
        );

        await this.setTable(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setTable(table: TableApiInterface) {
        const result = await prepareTable(table, this.statuses);

        this.context.commit('SET_TABLE', result);
    }

    @Action({ rawError: true })
    async updateSellerType(selected: { id: string; value: string }) {
        this.context.commit('RESET_SELLER_TYPE_ERROR');
        await this.context.commit('SET_SELLER_TYPE', selected);
        this.getList();
    }

    @Action({ rawError: true })
    clearSort() {
        this.context.commit('SET_SORT', {});
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSelect(params: FilterSelectParamsInterface) {
        await this.filter.updateSelect(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateStartDate(params: FilterDateParamsInterface) {
        await this.filter.updateStartDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateEndDate(params: FilterDateParamsInterface) {
        await this.filter.updateEndDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateMultiSelect(params: FilterMultiSelectParamsInterface) {
        await this.filter.updateMultiSelect(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSuggestionsSelected(params: FilterInputSuggestionsParamsInterface) {
        await this.filter.updateSuggestionsSelected(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateAuctionHouseSuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        const table = await getAuctionHouseList(
            1,
            this.pageSettings.filterRequestSort ?? '',
            `&filters[0][id]=name&filters[0][value]=${params.value}`,
            50,
        );
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }

    @Action({ rawError: true })
    async updateGallerySuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        const table = await getGalleryList(
            1,
            this.pageSettings.filterRequestSort ?? '',
            `&filters[0][id]=name&filters[0][value]=${params.value}`,
            50,
        );
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }

    @Action({ rawError: true })
    async updatePainterSuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        const table = await getPainterList(
            1,
            this.pageSettings.filterRequestSort ?? '',
            `&filters[0][id]=name&filters[0][value]=${params.value}`,
            50,
        );
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }
}
export default getModule(OrdersModule);
