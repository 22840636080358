
import { Component, Mixins, Watch } from 'vue-property-decorator';
import LotMixin from '../lotCardMixin';
import AuctionLotEntity from '@/admin/store/auctions/lots/entity';
import UserModule from '@/admin/store/user';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import RemoveModal from '@/components/modules/removeModal.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { RoleEnum } from '@/admin/store/role';
import LocalizationLayout from '@/admin/layouts/LocalizationLayout.vue';
import { formatRemoveSpaces } from '@/lib/utils/Utils';
import { PainterProductivityCategoryInterface } from '@/interfaces/settingsAuctions';

@Component({
    components: {
        RemoveModal,
        TabsNav,
    },
})
export default class PainterLotCardModuleInfo extends Mixins(LotMixin, LocalizationLayout) {
    componentCounter = 0;

    get isAllowRemove() {
        return AuctionLotEntity.isAllowRemove;
    }

    get tabsNav() {
        return AuctionLotEntity.tabsNav;
    }

    get currentTab() {
        return AuctionLotEntity.tabsNav.card.id;
    }

    get role(): RoleEnum | null {
        return UserModule.role;
    }

    get isAllowEdit(): boolean {
        if (!this.role?.length) {
            return false;
        }

        const isRoleSuperAdmin = this.role === 'ROLE_SUPER_ADMIN';

        let bool = true;

        if (this.model.bidsCount && !isRoleSuperAdmin) {
            bool = false;
        }

        if (this.model.status === 'sold' && !isRoleSuperAdmin) {
            bool = false;
        }

        return bool;
    }

    get componentCounterTrigger(): boolean[] {
        return [this.model.hideImage.list[0].checked, this.model.hidePrice.list[0].checked];
    }

    @Watch('componentCounterTrigger', { immediate: true })
    onComponentCounterTrigger(): void {
        this.componentCounter++;
    }

    get lotCurrenciesClass(): string {
        const colEnd = this.model.exchangeRateType.value === 'indicative' ? 9 : 8;

        return `column-grid-4-${colEnd}`;
    }

    get settingsCurrentCategory(): PainterProductivityCategoryInterface | null {
        return this.module.painterSettingsCurrentCategory;
    }

    updateSearchPainter(search: string): void {
        AuctionLotEntity.searchPainter(search);
    }

    updateSelectedPainter(selected: InputSuggestionItem): void {
        AuctionLotEntity.updatePainter({ selected });
    }

    updateCategorySelect(selected: SelectItem): void {
        AuctionLotEntity.updateCategorySelect(selected);
    }

    updatePainterStartPrice(value: string): void {
        const clearedValue = formatRemoveSpaces(value);

        this.module.RESET_ERROR_BY_FIELD('painterStartPrice');
        this.module.updateModelFieldValue({ field: 'painterStartPrice', value: clearedValue });
    }

    updatePainterMaxPrice(value: string): void {
        const clearedValue = formatRemoveSpaces(value);

        this.module.RESET_ERROR_BY_FIELD('painterMaxPrice');
        this.module.updateModelFieldValue({ field: 'painterMaxPrice', value: clearedValue });
    }

    updatePainterBuyNowPrice(value: string): void {
        const clearedValue = formatRemoveSpaces(value);

        this.module.RESET_ERROR_BY_FIELD('painterBuyNowPrice');
        this.module.updateModelFieldValue({ field: 'painterBuyNowPrice', value: clearedValue });
    }

    async mounted(): Promise<void> {
        await this.init();
        this.componentCounter++;
    }
}
