export const tabsNav = {
    auctions: {
        id: 'auctions',
        name: 'Каталоги',
        template: 'auctions',
        pathName: 'auctions',
        icon: 'book',
        iconSize: '16',
    },
    lots: {
        id: 'lots',
        name: 'Лоты',
        template: 'lots',
        pathName: 'lots',
        icon: 'pencil',
        iconSize: '16',
    },
    orders: {
        id: 'orders',
        name: 'Заказы',
        template: 'orders',
        pathName: 'orders',
        icon: 'bank',
        iconSize: '16',
    },
};
