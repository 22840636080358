
import { Mixins, Component } from 'vue-property-decorator';

import TableTitle from './_tableTitle.vue';
import IconFont from '@/components/icons/IconFont.vue';
import PaginationBlock from '@/components/Pagination.vue';
import BtnDefault from '@/components/buttons/BtnSubmitFake/BtnSubmitFake.vue';

import tableMixin from './tableMixin';
import { ApproveEnum } from '@/admin/views/auctions/card/_biddingTable.vue';

@Component({
    components: {
        TableTitle,
        IconFont,
        PaginationBlock,
        BtnDefault,
    },
})
export default class TableComponent extends Mixins(tableMixin) {
    get approveEnum(): typeof ApproveEnum {
        return ApproveEnum;
    }
}
