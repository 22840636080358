import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import store from '@/admin/store';
import EntityModel from './entityModel';

import { addLotToOrder, deleteLotFromOrder, editOrderCard, getOrderCard, getOrdersStatuses } from '@/admin/api/orders';
import { OrdersModelInterface } from '@/interfaces/models/orders.interface';
import { formatCreatedAt, getApiErrorMessage } from '@/lib/utils/Utils';
import { SelectItem, SelectList } from '@/lib/formFactory/select.interface';
import { processListForSelect } from '@/lib/utils/form';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { StatusHistoryListInterface } from '@/interfaces/orders.interface';
import { TableInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import UserModule from '@/admin/store/user';
import SiteModule from '@/admin/store/site';
import { RoleEnum } from '@/admin/store/role';

const MODULE_NAME = 'orderCard';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class OrderCardModule extends EntityBaseModule {
    orderId: number | null = null;
    model: OrdersModelInterface;
    defaultValue: SelectItem = { id: '', value: '' };
    statusHistoryTable: TableInterface | null = null;
    statusTitlesdict: { [key: string]: { name: string; visible: boolean } } = {
        id: {
            name: 'id',
            visible: false,
        },
        order: {
            name: 'order',
            visible: false,
        },
        createdAt: {
            name: 'Дата',
            visible: true,
        },
        status: {
            name: 'status',
            visible: false,
        },
        statusName: {
            name: 'Статус',
            visible: true,
        },
        createdByUser: {
            name: 'Пользователь',
            visible: true,
        },
    };

    constructor(module: OrderCardModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_ORDER_ID(id: number) {
        this.orderId = id;
    }

    @Mutation
    SET_COMMISSION_ACQUIRING(value: string) {
        this.model.commissionAcquiring = value;
    }

    @Mutation
    SET_COMMISSION_AUCTION_HOUSE(value: string) {
        this.model.commissionAuctionHouse = value;
    }

    @Mutation
    SET_COMMISSION_FINANCIAL_SERVICES(value: string) {
        this.model.commissionFinancialServices = value;
    }

    @Mutation
    SET_COMMISSION_RIGHT_TO_FOLLOW(value: string) {
        this.model.commissionRightToFollow = value;
    }

    @Mutation
    SET_COMMISSION_SITE(value: string) {
        this.model.commissionSite = value;
    }

    @Mutation
    SET_LAST_STATUS_CHANGE_DATE(value: string) {
        this.model.dateTimeLastStatusChange = value;
    }

    @Mutation
    SET_STATUS(status: SelectItem) {
        this.model.status.current = status;
    }

    @Mutation
    SET_COMMENT(value: string) {
        this.model.commentCancellation.value = value;
    }

    @Mutation
    SET_USER_ID(id: number | null) {
        this.model.userId = id;
    }

    @Mutation
    SET_USER_NAME(name: string) {
        this.model.userName = name;
    }

    @Mutation
    SET_SELLER_TYPE(type: string) {
        this.model.sellerType = type;
    }

    @Mutation
    SET_SELLER_ID(id: number) {
        this.model.sellerId = id;
    }

    @Mutation
    SET_SELLER_NAME(name: string) {
        this.model.sellerName = name;
    }

    @Mutation
    SET_SELLER_PARAM_NAME(name: string) {
        this.model.sellerParamName = name;
    }

    @Mutation
    SET_STATUS_LIST(list: SelectList) {
        this.model.status.list = list;
    }

    @Mutation
    SET_COST_DELIVERY(value: string) {
        this.model.costDelivery.value = value;
    }

    @Mutation
    SET_COST_ORDER(value: number) {
        this.model.costOrder = value;
    }

    @Mutation
    SET_COST_LOTS(value: number) {
        this.model.costLots = value;
    }

    @Mutation
    SET_COST_TOTAL(value: number) {
        this.model.costTotal = value;
    }

    @Mutation
    SET_STATUS_HISTORY_TABLE(table: TableInterface | null) {
        this.statusHistoryTable = table;
    }

    @Mutation
    SET_CATALOG(catalog: { id: number; value: string } | null) {
        this.model.catalog = catalog;
    }

    @Action({ rawError: true })
    async init(cardId: string) {
        await this.initStatuses();
        this.setInfo(await getOrderCard(cardId));
    }

    @Action({ rawError: true })
    async initStatuses() {
        const result = await getOrdersStatuses();

        let statusesProcessed = processListForSelect(result);
        if (UserModule.role !== RoleEnum.ROLE_SUPER_ADMIN) {
            statusesProcessed = statusesProcessed.map((status) => {
                if (status.id === 'canceled') {
                    status.visible = false;
                }

                return status;
            });
        }

        this.context.commit('SET_STATUS_LIST', statusesProcessed);
    }

    @Action({ rawError: true })
    updateStatus(status: SelectItem) {
        this.context.commit('SET_STATUS', status);
    }

    @Action({ rawError: true })
    updateComment(value: string) {
        this.context.commit('SET_COMMENT', value);
    }

    @Action({ rawError: true })
    updateCostDelivery(value: string) {
        this.context.commit('SET_COST_DELIVERY', value);
    }

    @Action({ rawError: true })
    updateCostTotal() {
        this.context.commit(
            'SET_COST_TOTAL',
            this.model.costOrder ? this.model.costOrder + Number(this.model.costDelivery.value) : 0,
        );
    }

    @Action({ rawError: true })
    async setInfo(data: any) {
        this.context.commit('SET_ORDER_ID', data.id);
        this.context.commit('SET_CATALOG', data.auctionId ? { id: data.auctionId, value: data.auctionName } : null);
        this.context.commit('SET_COMMISSION_ACQUIRING', data.commissionAcquiring ? data.commissionAcquiring : '-');
        this.context.commit(
            'SET_COMMISSION_AUCTION_HOUSE',
            data.commissionAuctionHouse ? data.commissionAuctionHouse : '-',
        );
        this.context.commit(
            'SET_COMMISSION_FINANCIAL_SERVICES',
            data.commissionFinancialServices ? data.commissionFinancialServices : '-',
        );
        this.context.commit(
            'SET_COMMISSION_RIGHT_TO_FOLLOW',
            data.commissionRightToFollow ? data.commissionRightToFollow : '-',
        );
        this.context.commit('SET_COMMISSION_SITE', data.commissionSite ? data.commissionSite : '-');
        this.context.commit('SET_CREATED_AT', formatCreatedAt(data.createdAt));
        this.context.commit(
            'SET_LAST_STATUS_CHANGE_DATE',
            data.dateTimeLastStatusChange ? formatCreatedAt(data.dateTimeLastStatusChange) : '-',
        );

        this.context.commit('SET_USER_ID', data.customer.id ? data.customer.id : null);

        const userName = [data.customer.firstName, data.customer.middleName, data.customer.lastName]
            .filter((item) => item != null)
            .join(' ');
        this.context.commit('SET_USER_NAME', userName);

        if (data.sellerAuctionHouse != null) {
            this.context.commit('SET_SELLER_TYPE', 'auction_house');
            this.context.commit('SET_SELLER_PARAM_NAME', 'auctionHouseId');
            this.context.commit('SET_SELLER_ID', data.sellerAuctionHouse.id);
        }

        if (data.sellerGallery != null) {
            this.context.commit('SET_SELLER_TYPE', 'gallery');
            this.context.commit('SET_SELLER_PARAM_NAME', 'galleryId');
            this.context.commit('SET_SELLER_ID', data.sellerGallery.id);
        }

        if (data.sellerPainter != null) {
            this.context.commit('SET_SELLER_TYPE', 'painter');
            this.context.commit('SET_SELLER_PARAM_NAME', 'painterId');
            this.context.commit('SET_SELLER_ID', data.sellerPainter.id);
        }

        this.context.commit('SET_SELLER_NAME', data.sellerName ? data.sellerName : '');
        this.context.commit(
            'SET_STATUS',
            data.status
                ? this.model.status.list.filter((item: SelectItem) => item.id === data.status)[0]
                : this.defaultValue,
        );

        this.context.commit('SET_COST_DELIVERY', data.costDelivery);
        this.context.commit('SET_COST_ORDER', data.costOrder - data.costDelivery);
        this.context.commit('SET_COST_LOTS', data.costLots ?? 0);
        this.updateCostTotal();

        this.setOrderHistoryList(data.orderStatusHistories);

        this.context.commit('SET_IS_LOADING', false);
    }

    @Action({ rawError: true })
    prepareOrderHistoryTable(list: StatusHistoryListInterface) {
        const titles: TableTitle[] = Object.keys(list[0]).map((item) => {
            return {
                id: item,
                name: this.statusTitlesdict[item].name,
                visible: this.statusTitlesdict[item].visible,
            };
        });

        const hiddenColumns: any[] = titles.filter((item) => item.visible === false).map((item) => item.id);

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(list)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'createdByUser') {
                    const fullName = [cell.firstName, cell.middleName, cell.lastName]
                        .filter((item) => item != null)
                        .join(' ');
                    cell = UserModule.isSuperAdmin
                        ? `<a href="${window.location.origin}/users/user/${cell.id}">${fullName}</a>`
                        : fullName;
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: titles,
            rows: rows,
            sortFields: [],
            pagination: {},
            totalItems: list.length,
            filters: {},
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    async setOrderHistoryList(list: StatusHistoryListInterface) {
        this.SET_STATUS_HISTORY_TABLE(list?.length ? await this.prepareOrderHistoryTable(list) : null);
    }

    @Action({ rawError: true })
    setPrices(data: any) {
        this.SET_COST_DELIVERY(data.costDelivery);
        this.SET_COST_ORDER(data.costOrder - data.costDelivery);
        this.SET_COST_LOTS(data.costLots ?? 0);
    }

    @Action({ rawError: true })
    async validate() {
        this.setValidateSelect({ index: 'status', field: this.model.status });

        const fieldsWithError = await this.getFieldsWithError();

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareRequestData() {
        const data: { status: string; costDelivery?: number; commentCancellation?: string } = {
            status: String(this.model.status.current.id),
        };

        if (this.model.costDelivery.value) {
            data['costDelivery'] = Number(this.model.costDelivery.value);
        }

        if (this.model.commentCancellation.value) {
            data['commentCancellation'] = this.model.commentCancellation.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit() {
        if (!this.orderId) {
            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        const data = await this.prepareRequestData();

        try {
            const result = await editOrderCard(this.orderId, data);

            if (result.id) {
                this.setInfo(result);
            }

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }

            SiteModule.SET_IS_BLOCK(false);
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
            SiteModule.SET_IS_BLOCK(false);
        }
    }

    @Action({ rawError: true })
    async addLot(lotId: number) {
        if (!this.orderId) {
            return;
        }

        try {
            const result = await addLotToOrder(this.orderId, lotId);

            this.setPrices(result);
            this.updateCostTotal();

            this.setOrderHistoryList(result.orderStatusHistories);

            ResponseHandlerModule.showNotify({ message: 'Лот успешно добавлен к заказу', type: 'ok' });
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async removeLot(lotId: number) {
        if (!this.orderId) {
            return;
        }

        try {
            const result = await deleteLotFromOrder(this.orderId, lotId);

            this.setPrices(result);
            this.updateCostTotal();

            if (result.status === 'ok') {
                ResponseHandlerModule.showNotify({ message: 'Лот успешно удален из заказа', type: 'ok' });

                return;
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    reset() {
        this.SET_ORDER_ID(0);
        this.SET_COMMISSION_ACQUIRING('-');
        this.SET_COMMISSION_AUCTION_HOUSE('-');
        this.SET_COMMISSION_FINANCIAL_SERVICES('-');
        this.SET_COMMISSION_RIGHT_TO_FOLLOW('-');
        this.SET_COMMISSION_SITE('-');
        this.SET_CREATED_AT('');
        this.SET_LAST_STATUS_CHANGE_DATE('');

        this.SET_USER_ID(null);
        this.SET_USER_NAME('');

        this.SET_SELLER_TYPE('');
        this.SET_SELLER_PARAM_NAME('');
        this.SET_SELLER_ID(0);

        this.SET_SELLER_NAME('');
        this.SET_STATUS(this.defaultValue);

        this.SET_COST_ORDER(0);
        this.SET_COST_LOTS(0);
        this.updateCostTotal();
        this.SET_CATALOG(null);

        this.SET_STATUS_HISTORY_TABLE(null);
        this.SET_IS_LOADING(false);
    }
}

export default getModule(OrderCardModule);
