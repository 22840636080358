
import { Component, Mixins, Watch } from 'vue-property-decorator';
import LotMixin from '../lotCardMixin';
import AuctionLotEntity from '@/admin/store/auctions/lots/entity';
import UserModule from '@/admin/store/user';

import RemoveModal from '@/components/modules/removeModal.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { RoleEnum } from '@/admin/store/role';
import LocalizationLayout from '@/admin/layouts/LocalizationLayout.vue';

@Component({
    components: {
        RemoveModal,
    },
})
export default class GalleryLotCardModuleInfo extends Mixins(LotMixin, LocalizationLayout) {
    componentCounter = 0;

    get isAllowRemove(): boolean {
        return AuctionLotEntity.isAllowRemove;
    }

    get role(): RoleEnum | null {
        return UserModule.role;
    }

    get isAllowEdit(): boolean {
        if (!this.role?.length) {
            return false;
        }
        const isRoleSuperAdmin = this.role === 'ROLE_SUPER_ADMIN';

        let bool = true;

        if (this.model.status === 'sold' && !isRoleSuperAdmin) {
            bool = false;
        }

        return bool;
    }

    get componentCounterTriggers(): boolean[] {
        return [this.model.hidePrice.list[0].checked, this.model.hideImage.list[0].checked];
    }

    @Watch('componentCounterTriggers', { immediate: true })
    onComponentCounterTriggersChange(): void {
        this.componentCounter++;
    }

    updateSearchGallery(search: string): void {
        AuctionLotEntity.searchGallery(search);
    }

    updateSelectedGallery(selected: InputSuggestionItem): void {
        AuctionLotEntity.updateSuggestionsSelectedByType({ selected, type: 'sellerGallery' });
    }

    async mounted(): Promise<void> {
        await this.init();
        this.componentCounter++;
    }
}
