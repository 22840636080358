import { WEBSOCKET_URL } from '@/constants';
import WebsocketsModule from '@/admin/store/websockets';
import { SocketBidParams } from '@/interfaces/catalogPublic.interface';

export interface BidType {
    id: number;
    bid: number;
    bidType: string;
    createdAt: string;
    approved: boolean | null;
    number: number;
    user: { id: number };
}

export interface NewLotMessageData {
    bids: BidType[];
    nextBid: number;
    lotId: number;
    timeToEnd: number;
}

export interface NewBidMessageData {
    lotId: number;
    nextBid: number;
    timeToEnd: number;
    newBid: BidType[];
}

export interface UserNotificationMessageData {
    message: string;
    aboutType: string;
    aboutId: number;
    messageId: string;
}

export type MessageTypes = 'newLot' | 'newBid';
export interface Subscribers extends Partial<Record<MessageTypes, (data: any) => void>> {
    newLot?: (data: NewLotMessageData) => void;
    endLot?: (data: NewLotMessageData) => void;
    cancelLot?: () => void;
    newBid?: (data: SocketBidParams) => void;
    bid?: (data: string) => void;
    autoBid?: (data: string) => void;
    started?: () => void;
    lotSold?: () => void;
    lotTradingStated?: () => void;
    lastChance?: () => void;
    finished?: () => void;
    user?: (data: UserNotificationMessageData) => void;
    operator?: (data: any) => void;
    autoApprovedChanged?: (data: { bidAutoApprove: boolean }) => void;
    bidAutoApprovedChanged?: (data: { bidAutoApprove: boolean }) => void;
}

type userSubscribeTypes = 'user' | 'bid' | 'autoBid';
type userSubscribeOverload = {
    (id: number | string, type: 'user', subscriber: (data: UserNotificationMessageData) => void): void;
    (id: number | string, type: 'bid', subscriber: (data: string) => void): void;
    (id: number | string, type: 'autoBid', subscriber: (data: string) => void): void;
};

export interface WS {
    ws: WebSocket;
    auctionSubscribe: (id: number, subscribers: Subscribers) => void;
    lotSubscribe: (id: number, subscribers: Subscribers) => void;
    userSubscribe: (id: number|string, subscribers: Subscribers) => void;
    addUserSubscribe: userSubscribeOverload;
    makeBet: (id: number, price: number) => void;
    autoBid: (id: number, price: number) => void;
    close: () => void;
    reset: () => void;
}

interface Message {
    aboutType: 'auction';
    aboutId: number;
    messageType: MessageTypes;
    message: {
        bids: [];
        lotId: number;
        nextBid: number;
        timeToEnd: number;
    };
}

const RETRIES_AMOUNT = 3;
let retries = RETRIES_AMOUNT;

const websockets = (): Promise<WS> => {
    const subscriptions: Record<string, Subscribers> = {};

    return new Promise((resolve, reject) => {
        const socket = new WebSocket(WEBSOCKET_URL, ['access-token', WebsocketsModule.token]);

        socket.onopen = () => {
            console.log('Websocket connection is set up successfully!'); // eslint-disable-line
            const ws: WS = {
                ws: socket,
                auctionSubscribe: (id, subscribers) => {
                    socket.send(JSON.stringify({ type: 'subscribe', aboutType: 'auction', aboutId: id }));
                    subscriptions[`auction-${id}`] = subscribers;
                },
                lotSubscribe: (id, subscribers) => {
                    socket.send(JSON.stringify({ type: 'subscribe', aboutType: 'lot', aboutId: id }));
                    subscriptions[`lot-${id}`] = subscribers;
                },
                userSubscribe: (id, subscribers) => {
                    subscriptions[`user-${id}`] = subscribers;
                },

                addUserSubscribe: (id: number|string, type: userSubscribeTypes, subscriber: any) => {
                    if (!subscriber) {
                        return;
                    }

                    if (!subscriptions[`user-${id}`]) {
                        subscriptions[`user-${id}`] = {};
                    }

                    subscriptions[`user-${id}`][type] = subscriber;
                },

                makeBet: (id, price) => {
                    socket.send(JSON.stringify({ type: 'bid', lotId: id, bid: price }));
                },
                autoBid: (id, price) => {
                    socket.send(JSON.stringify({ type: 'autoBid', lotId: id, bid: price }));
                },

                close: () => {
                    socket?.close(1000);
                    WebsocketsModule.changeWs(null);
                },
                reset: async () => {
                    socket.close(1000);
                    retries = RETRIES_AMOUNT;
                    await websockets();
                },
            };
            WebsocketsModule.changeWs(ws);
            resolve(ws);
        };

        socket.onmessage = (message: MessageEvent) => {
            try {
                const data: Message = JSON.parse(message.data);
                try {
                    data.message = JSON.parse((data.message as unknown) as string);
                    subscriptions[`${data.aboutType}-${data.aboutId}`]?.[data.messageType as keyof Subscribers]?.(data.message as any);
                } catch (_) {
                    subscriptions[`${data.aboutType}-${data.aboutId}`]?.[data.messageType as keyof Subscribers]?.(data.message as any);
                }
            } catch (error) {
                throw new Error(JSON.stringify(error));
            }

            return false;
        };

        socket.onerror = () => {
            console.error('Failed to connect the socket to url: ', WEBSOCKET_URL);
        };

        socket.onclose = () => {
            if (retries > 0) {
                console.warn('Websocket connection was closed');
                WebsocketsModule.changeWs(null);
                retries--;
                setTimeout(() => websockets(), 1500);
            } else {
                console.error('Cannot setup the WebSocket connection after 3 retries');
                reject(new Error('Cannot setup the WebSocket connection after 3 retries'));
            }
        };
    });
};

export default websockets;
