
import { Vue, Component } from 'vue-property-decorator';
import AuctionHouseLotCard from './_lotCardAuctionHouse.vue';
import PainterLotCard from './_lotCardPainter.vue';
import GalleryLotCard from './_lotCardGallery.vue';

@Component({
    components: {
        AuctionHouseLotCard,
        PainterLotCard,
        GalleryLotCard,
    },
})
export default class LotCardMain extends Vue {}
