
import { Vue, Component } from 'vue-property-decorator';

import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import BtnSubmitFake from '@/components/buttons/BtnSubmitFake/BtnSubmitFake.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import RemoveModal from '@/components/modules/removeModal.vue';

import LotEntity from '@/admin/store/auctions/lots/entity';

@Component({
    components: {
        BtnSubmit,
        BtnDefault,
        BtnSubmitFake,
        RemoveModal,
    },
})
export default class LotFormBottom extends Vue {
    get isCreatingLot(): boolean {
        return this.$route.name === 'lot_create';
    }

    get isShowEdit(): boolean {
        return LotEntity.isAllowEdit || this.isCreatingLot;
    }

    get isShowRemove(): boolean {
        return LotEntity.isAllowRemove && !this.isCreatingLot;
    }

    get isShowPublish(): boolean {
        return LotEntity.isAllowPublish && !this.isCreatingLot;
    }

    get isShowWithdraw(): boolean {
        return LotEntity.isAllowWithdraw && !this.isCreatingLot;
    }

    publish(): void {
        this.$emit('publish');
    }

    withdraw(): void {
        this.$emit('withdraw');
    }

    redirect(): void {
        if (LotEntity.model.auction.current.id) {
            this.$router.push({ name: 'auction_lots', params: { catalogId: LotEntity.model.auction.current.id } });

            return;
        }

        if (LotEntity.model.sellerPainter.current.id) {
            this.$router.push({
                name: 'painter_lots',
                params: { painterId: LotEntity.model.sellerPainter.current.id },
            });

            return;
        }

        if (LotEntity.model.sellerGallery.current.id) {
            this.$router.push({
                name: 'gallery_lots',
                params: { galleryId: LotEntity.model.sellerGallery.current.id },
            });

            return;
        }

        if (LotEntity.model.auctionHouse.current.id) {
            this.$router.push({
                name: 'auction_house_lots',
                params: { auctionHouseId: LotEntity.model.auctionHouse.current.id },
            });

            return;
        }

        this.$router.push({ name: 'lots' });
    }

    returnBefore(): void {
        if (this.$route.params.catalogId) {
            this.$router.push({ name: 'auction_lots', params: { auctionId: this.$route.params.catalogId } });

            return;
        }

        this.$router.push({ name: 'lots' });
    }
}
