
import { Component, Vue } from 'vue-property-decorator';

import { API_URL } from '@/constants';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import LotCard from './components/AuctionHouseInfo.vue';
import LotBets from './components/Bets.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import AuctionLotEntity from '@/admin/store/auctions/lots/entity';

@Component({
    components: {
        TabsNav,
        LotCard,
        LotBets,
        TitleReturn,
    },
})
export default class AuctionLotCardModule extends Vue {
    get titleReturnData(): { icon: string; title: string } {
        return {
            icon: 'pencil',
            title: this.$route.params.lotId
                ? AuctionLotEntity.titleReturnData.edit.title
                : AuctionLotEntity.titleReturnData.create.title,
        };
    }

    get link(): string {
        if (this.$route.name === 'lot_create') {
            return '';
        }

        return `${API_URL}/lots/${this.$route.params.lotId}`;
    }

    returnBefore(): void {
        this.$router.push({ name: 'lots' });
    }

    beforeDestroy(): void {
        AuctionLotEntity.resetTitleReturnName();
    }
}
