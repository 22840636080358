
import { Component, Prop, Vue } from 'vue-property-decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import AuctionsModule from '@/admin/store/auctions';

@Component({
    components: {
        TabsNav,
        TitleReturn,
    },
})
export default class AuctionsHeader extends Vue {
    @Prop({ required: false }) readonly currentTab?: string;
    @Prop({ required: false }) readonly title?: string;
    @Prop({ required: false }) readonly icon?: string;

    get tabsNav() {
        return AuctionsModule.tabsNav;
    }
}
