import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { getLotsList, getLotsStatuses } from '@/admin/api/lots';
import AuctionLots from '@/admin/store/auctions/lots';
import { prepareTable } from '@/admin/lib/Lots';
import { getAuctionHouseList } from '@/admin/api/auctionHouse';
import { getGalleryList } from '@/admin/api/gallery';
import { getPainterList } from '@/admin/api/painter';
import { processListForSelect } from '@/lib/utils/form';

import FilterModel from './lotsFilter';
import FilterEntity from './lotsFilterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import {
    FilterDateParamsInterface,
    FilterInputSuggestionsParamsInterface,
    FilterSearchParamsInterface,
    FilterSelectParamsInterface,
} from '@/lib/layouts/page/filterParams.interface';
import { generateSort } from '@/lib/utils/Utils';

export const MODULE_NAME = 'catalogsAuctionsLots';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CatalogsAuctionsLotsModule extends PageBaseModule {
    auctionId: number | null = null;
    filter: Filter;
    sellerType = AuctionLots.sellerType;
    statuses: { id: string; name: string }[] = [];

    constructor(module: CatalogsAuctionsLotsModule) {
        super(module);
        const page = new PageEntity();

        const filter = new FilterModel();
        this.filter = getModule(FilterEntity);
        this.filter.setFilterName('catalogsAuctionsLotsFilter');
        this.filter.setTemplateClassName('template-lg');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);

        page.setTitle('Лоты');
        page.setSortName('catalogsAuctionLostSort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;
    }

    @Mutation
    SET_AUCTION_ID(auctionId: number) {
        this.auctionId = auctionId;
    }

    @Mutation
    SET_STATUSES(list: { id: string; name: string }[]) {
        this.statuses = list;
    }

    @Mutation
    UPDATE_ROW_CHECKED(id: number) {
        const table = this.pageSettings.table as TableInterface;
        table.rows = table.rows.map((row) => {
            if (row.id === id) {
                row.checked = !row.checked;
            }

            return row;
        });

        table.titles.forEach((current, index) => {
            if (current.name === 'check' && current.value === true) {
                table.titles[index].value = false;
            }
        });

        this.pageSettings.table = table;
    }

    @Action({ rawError: true })
    initSellerTypes() {
        return [
            { id: 'auction_house', value: 'Аукционные дома' },
            { id: 'painter', value: 'Авторы' },
            { id: 'gallery', value: 'Галереи' },
        ];
    }

    @Action({ rawError: true })
    async init(auctionId: string) {
        this.context.commit('SET_AUCTION_ID', Number(auctionId));
        this.setDefaultSort();
        this.SET_FILTER_REQUEST_SORT(await generateSort({ id: 'name', value: 'ASC' }));

        this.context.commit('SET_STATUSES', await getLotsStatuses());

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.context.commit('SET_IS_LOADING', true);
        const sort = await this.getSortForRequest();

        const filter = this.auctionId
            ? `&filters[0][id]=auction&filters[0][value]=${this.auctionId}${this.filter.filterSettings.filter}`
            : this.filter.filterSettings.filter;
        const result = await getLotsList(this.pageSettings.pageCurrent, sort, filter, 0);

        await this.setList(result);
        this.context.commit('SET_IS_LOADING', false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await prepareTable(table, this.statuses, false);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    toggleCheckAll() {
        const table = this.pageSettings.table as TableInterface;
        let value = false;

        const titles = table.titles.map((title) => {
            if (title.name === 'check') {
                title.value = !title.value;
                value = title.value;
            }

            return title;
        });

        const rows = table.rows.map((row) => {
            row.checked = value;

            return row;
        });

        table.titles = titles;
        table.rows = rows;

        this.SET_TABLE(table);
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSelect(params: FilterSelectParamsInterface) {
        await this.filter.updateSelect(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    updateSuggestionsSelectedFilter(params: FilterInputSuggestionsParamsInterface) {
        this.filter.updateSuggestionsSelected(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSuggestionsSearchFilter(params: { key: string; value: string }) {
        let table: TableApiInterface | null = null;
        const filter = params.value ? `&filters[0][id]=name&filters[0][value]=${params.value}` : '';
        this.filter.updateSuggestionsValue(params);

        if (params.key === 'sellerAuctionHouse') {
            table = await getAuctionHouseList(1, this.pageSettings.filterRequestSort ?? '', filter, 50);
        }

        if (params.key === 'sellerGallery') {
            table = await getGalleryList(1, this.pageSettings.filterRequestSort ?? '', filter, 50);
        }

        if (params.key === 'sellerPainter') {
            table = await getPainterList(1, this.pageSettings.filterRequestSort ?? '', filter, 50);
        }

        const list = table ? Object.values(table.rows) : [];

        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(list) });
    }

    @Action({ rawError: true })
    async updateStartDate(params: FilterDateParamsInterface) {
        await this.filter.updateStartDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateEndDate(params: FilterDateParamsInterface) {
        await this.filter.updateEndDate(params);
        this.filter.updateFilter();
    }
}

export default getModule(CatalogsAuctionsLotsModule);
