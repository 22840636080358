
import { Prop, Component, Vue } from 'vue-property-decorator';

import ModalBlock from '@/components/Modal.vue';

@Component({
    components: {
        ModalBlock,
    },
})
export default class BiddingLotDescriptionModal extends Vue {
    @Prop() readonly description!: string;

    title = 'Описание';

    closeModal(): void {
        this.$emit('click');
    }
}
